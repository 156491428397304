@import 'scss/variables.scss';

.modal {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .modal-content {
    padding: 50px 100px;
    // text-align: center;
    max-height: 50vh;
    overflow: auto;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }

  .next-back-buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 80px;
  }

  .submit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 80px;
  }

  .back {
    align-self: center;
    background-color: white;
    color: #044f9c;
    border: 2px solid #044f9c;

    &:hover {
      border: 2px solid #044f9c;
      background-color: #c7e9f7;
    }
  }

  .back-disabled {
    align-self: center;
    background-color: white;
  }

  .submit {
    align-self: flex-end;
  }

  .textarea {
    padding: 10px;
    resize: vertical;
    border: 2px solid #e0e0e0;
  }

  .answer-wrapper {
    margin-bottom: 40px;
  }

  .answers {
    margin-left: 20px;
  }
}

.wait {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  text-align: center;
}
