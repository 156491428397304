@import 'scss/variables.scss';

.add-new-incident-form {
  margin-top: 20px;
  padding-bottom: 50px;
  container-type: inline-size;

  a {
    color: #21a9e1 !important;
    text-decoration: none;
    color: black;
    position: relative;
    display: inline;
    margin-bottom: 2px;
    block-size: fit-content;
    width: fit-content;
  }

  a:hover {
    color: #21a9e1;
  }

  a::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #21a9e1;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  a:hover::before {
    transform: scaleX(1);
  }
  .add-new-incident-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 25px;
    align-items: flex-start;
    padding-bottom: 20px;
    margin: 0 50px;

    @container (max-width: 840px) {
      display: flex;
      flex-direction: column;
    }

    .investigation-status {
      align-self: flex-start;
    }

    .date-inputs-row {
      .date-input {
        width: 100%;
        background-color: white;
      }
    }

    .affected {
      display: flex;
    }

    .last_note {
      grid-column: span 2;

      textarea {
        padding: 10px;
        height: 160px;
        resize: vertical;
        margin-bottom: 20px;
      }
    }
  }

  .tabs-container {
    display: flex;
    padding: 0 50px;
    background-color: $grey200-1;
  }

  .add-new-incident-tab-content {
    margin: 0 50px;
  }

  .hr {
    margin: 50px auto;
  }

  .incident-description {
    width: 100%;
    background-color: white;
    border: 2px solid $grey300-2;
    padding: 25px;
    font-size: 1.8rem;
    color: $grey900-5;
    resize: none;
  }

  .form-actions {
    display: grid;
    grid-template-columns: max-content auto max-content max-content;
    grid-gap: 25px;

    .cancel-button {
      grid-column: -2/-3;
      @container (max-width: 840px) {
        font-weight: 400;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .update-incident-button {
      grid-column: -1/-2;
      @container (max-width: 840px) {
        font-weight: 400;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .box-title {
    margin: 0;
    margin-bottom: 10px;
    color: #444444;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .parent-info-box {
    border: 2px solid #e3e3e3;
    background-color: #f1f1f1;
    padding: 10px;
  }

  .bold {
    font-weight: 500;
  }
}

.marginLeft {
  margin-left: 20px;
}

a.icon-link::before {
  width: 0;
}
.notes_tab {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
