@import 'scss/variables.scss';

.modal-content {
  padding: 10px 20px;

  h2 {
    text-align: left;
    font-weight: 500;
  }
}

.modal_content_wrapper {
  display: flex;
  justify-content: space-between;
}

.modal_content_left {
  display: flex;
  justify-content: flex-start;
  width: calc(50% - 12px);
}

.modal_content_right {
  display: flex;
  justify-content: flex-start;
  width: calc(50% - 12px);
}

.modal_content {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  width: 100%;
}

.sign_in {
  display: flex;
  justify-content: center;
}

.sign_in_btn {
  width: 300px;
}

.sign_out {
  display: flex;
  justify-content: flex-end;
}

.sign_out_btn {
  width: 100px;
}

.save_btn {
  margin-left: 20px;
  align-self: center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.hint {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 20px;
}

.org_list {
  grid-gap: 20px;
}

.user_list {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  margin: 10px 0 20px;
  text-align: left;
}

.user_list_item {
  max-width: 400px;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
