@import 'scss/variables.scss';

.modal {
  .modal-content {
    padding: 50px;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }
}

.holder {
  display: flex;
}

.department {
  margin-left: 20px;
  align-self: flex-start;
}

.reminder {
  margin-left: 20px;
  align-self: flex-start;
}

.complete {
  margin-top: 20px;
  margin-left: -5px;
}

.custom_radio_group {
  align-self: end;
}
