@import 'scss/variables.scss';

.modal {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;

  .modal-content {
    padding: 50px 0px 20px 0px;
    text-align: center;
    font-size: 22px;
  }

  .modal-reassign {
    margin: 20px auto 50px;
    width: 400px;
  }

  .modal-reassign-desc {
    margin-bottom: 20px;
    text-align: center;
  }

  .modal-reassign-note {
    display: block;
    margin-top: 10px;
    text-align: center;
  }

  .modal-reassign-button {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }

  .textarea {
    padding: 10px;
    resize: vertical;
    border: 2px solid $grey300-2;
  }
}
