$font-family-1: 'Roboto', 'Helvetica', 'Arial', sans-serif;

// COLORS
// The colors with a dash number (-1 or -2) are more often used
// grey200-1 is more often used than grey400
// TODO: why are all those colors here?
$blue1-dark: #0a407e;
$grey100: #f5f5f5;
$grey200-1: #eeeeee;
$grey300-2: #e0e0e0;
$grey400: #bdbdbd;
$grey500-3: #9e9e9e;
$grey600: #757575;
$grey700-4: #616161;
$grey800: #424242;
$grey900-5: #212121;
$blue2: #21a9e1;
$blue1-dark: #0a407e;
$blue2-dark: #1e98ca;
$green1: #8cc640;
$green1-dark: #7eb239;
$grey50: #fafafa;
$red1: #e2234b;
$orange-main: #ff9100;
$blue1-byRoland: #044f9c;
$blue2-light-hover: #e8f6fc;
$blue3-dark: #06284e;
$orange-light: #ffc87f;
$green1-light-success-notifications: #e2f1cf;
$red1-light-error-notifications: #f9d3db;

$light-blue: #29aae2;

$color-text-normal: $grey900-5;

// temporary vars for header until we sort out consistent colors
$blue1-light: #0d509d;
$orange-dark-1: #ffb759;

$weight-lighter: 100;
$weight-light: 300;
$weight-normal: 400;
$weight-bold: 500;
$weight-bolder: 600;

$font-size-normal: 1.6rem;

// Header responsive breakpoints
$header-breakpoint-1: 1200px;
$header-breakpoint-2: 960px;
$header-breakpoint-3: 850px;
$header-breakpoint-4: 790px;
$header-breakpoint-5: 720px; // Mobile view switch

textarea {
  border: 2px solid #e0e0e0;
}

// LAYER STYLES
// For Login/Register body background
@mixin body-gradient-screen {
  background: rgb(10, 64, 126);
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 100% 0, #23a8e1 0%, #0a407e 100%);
}
// For the footer
@mixin footer {
  background: rgb(255, 255, 255);
  background: linear-gradient(60deg, rgba(255, 255, 255, 1) 0%, rgba(219, 229, 240, 1) 100%);
}

// CHARACTER STYLES
// These can then be moved to the theme
@mixin h1 {
  color: $blue1-byRoland;
  font-size: 4rem;
}
@mixin h2 {
  color: $grey900-5;
  font-size: 3.4rem;
}
@mixin h3 {
  color: $grey900-5;
  font-size: 2.4rem;
}
@mixin btn-lg {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 2px;
  // line-height: 7rem;
  height: 7rem;
  padding: 2rem 5rem;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
}
@mixin btn-lg-primary {
  @include btn-lg;
  color: white;
  background-color: $blue1-byRoland;

  &:hover {
    background-color: $blue1-dark;
  }
}
@mixin btn-lg-primary-outline {
  @include btn-lg;
  color: $blue1-byRoland;
  background-color: white;
  border: 2px solid $blue1-byRoland;
  box-sizing: border-box;

  &:hover {
    background-color: white;
  }
}
@mixin btn-lg-secondary {
  @include btn-lg;
  color: $grey700-4;
  border: 2px solid $grey500-3;
  background-color: white;
}
@mixin btn-lg-danger {
  @include btn-lg;
  color: white;
  background-color: $red1;

  // &:hover {
  //     background-color: #d1123a;
  // }
}
@mixin btn-md {
  color: $blue1-byRoland;
  font-size: 1.5rem;
  cursor: pointer;
}
@mixin btn-md-hover {
  color: $blue1-dark;
}
@mixin btn-sm {
  color: white;
  font-size: 1.4rem;
  cursor: pointer;
}
@mixin th {
  color: white;
  font-size: 1.4rem;
}
@mixin menu-link {
  color: $blue1-byRoland;
  font-size: 1.8rem;
}
@mixin sub-menu-link {
  color: $grey900-5;
  font-size: 1.6rem;
}
@mixin input {
  color: $grey900-5;
  font-size: 1.6rem;
  font-weight: 400;
  box-sizing: border-box;
  height: 55px;
  display: flex;
  align-items: center;
  border-radius: 0%;
  border: 2px solid $grey300-2;
}
@mixin input-label {
  // color: $grey700-4;
  // font-size: 1.4rem;
  // letter-spacing: 1px;
  // font-weight: bold;
  //
  color: #444444;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  font-weight: 500;
}
@mixin input-placeholder-active {
  color: $grey900-5;
  font-size: 1.6rem;
}
@mixin input-placeholder-inactive {
  color: $grey400;
  font-size: 1.6rem;
}
@mixin error-message {
  color: $red1;
  font-size: 1.4rem;
}

@mixin unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    margin: 0;
    padding: 0;
  }
}

@mixin boxshadow($depth: 1) {
  @if $depth==1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $depth==2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $depth==3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $depth==4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $depth==5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

// Bridge SCSS vars into JS
:export {
  blue1Dark: $blue1-dark;
  fontFamily1: $font-family-1;
  headerBreakpoint5: $header-breakpoint-5;
}

textarea:disabled {
  background-color: #f1f1f1 !important;
}

// Pixels occupied by collapsed and expanded menu
$collapsed: 65px;
$expanded: 260px;
