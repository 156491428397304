.info_panel {
  min-height: 300px;
  width: 100%;
  background-color: white;
  padding: 40px 30px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  min-width: 100%;

  .holder {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    .title {
      font-size: 21px;
      font-weight: 500;
    }

    .tabs {
      display: flex;
      margin-left: auto;
      cursor: pointer;
      font-weight: 500;
      color: #d1d1d6;
      border-bottom: 2px solid #d1d1d6;
      font-size: 14px;

      .active {
        margin-bottom: -2px;
        padding-bottom: 15px;
        color: #05b5f0;
        border-bottom: 2px solid #05b5f0;
      }

      & > div {
        padding: 0 20px;
        text-align: center;
        // margin-left: 20px;
      }
    }
  }
}

.empty {
  margin: 0 !important;
  box-shadow: none !important;
}

.table {
  position: relative;
  overflow: hidden;
  min-height: 429px;
}

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.cursor-default {
  cursor: default;
}
