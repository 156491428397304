.modules {
  text-align: left;

  h3 {
    font-size: 18px;
    font-weight: 500;
    padding: 0;
    margin: 20px 0 10px 0;
  }

  hr {
    border: 1px solid #e3e3e3;
  }

  .menu {
    margin-bottom: 50px;
  }

  .checkbox {
    display: flex;
    padding-left: 0px !important;
    // margin-bottom: 10px;
  }

  .row,
  .row-title {
    padding: 15px;
    width: 100%;
  }

  .row-title {
    background-color: #eee;
  }

  .acts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .modules {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .modules1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    // width: 50%;
  }
}
