@import 'scss/variables.scss';

.section_head {
  background-color: #666;
  color: #fff;
  font-weight: 400;
  padding: 15px;
  font-size: 16px;
  text-align: center;
}

.questions {
  font-size: 14px;

  & > div {
    padding: 8px 0;
    padding-left: 15px;
    display: flex;
    align-items: center;

    & > span {
      margin: 2px;
      min-width: 40px;
    }

    & > span:last-of-type {
      min-width: 240px;
      text-align: right;
      margin-left: auto;
      margin-right: 5px;

      input {
        cursor: pointer;
      }

      & > label {
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }

  div:nth-child(odd) {
    background: #ddd;
  }

  div:nth-child(even) {
    background: #fff;
  }
}

.holder {
  width: 100%;
  flex-direction: column;
  max-width: 950px;
  margin: 50px auto;
  border: 1px solid #b3b3b3;
  display: flex;
}

.submit {
  flex-direction: column;
  max-width: 250px;
  margin: 50px auto;
  text-align: center;
  display: flex;
  border-radius: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: white;
}

.logo {
  width: auto;
  margin: 20px auto 0;
}

.title {
  margin: 50px auto 0;
  font-weight: 500;
}

.completed {
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $blue1-light;
}
.completed_title {
  margin: 40px auto 0;
  font-size: 3.4rem;
  @media (max-width: 980px) {
    font-size: 2rem;
  }
}
.completed_sub_title {
  margin: 40px auto 0;
  font-size: 4.2rem;
  font-weight: 500;
  @media (max-width: 980px) {
    font-size: 2.4rem;
  }
}
.completed_text {
  margin: 40px auto 0;
  font-size: 2.6rem;
  @media (max-width: 980px) {
    font-size: 1.6rem;
  }
}
.completed_button {
  margin-top: 50px;
  font-size: 3.5rem !important;
  padding: 40px;
  border-radius: 12px;
  @media (max-width: 980px) {
    font-size: 2.4rem !important;
  }
}

.copyright {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
