.user_search {
  display: flex;
}

.checkbox {
  display: flex;
  width: 170px;
  margin-right: 10px;
}

.search {
  width: 150px;
}
