@import 'scss/variables.scss';

.input-adornment {
  border: 2px solid $grey300-2;
  box-sizing: border-box;
  max-width: 600px;
  height: 55px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  transition: 0.2s;

  &:focus-within {
    border: 2px solid $blue1-byRoland;
  }

  .icon-start,
  .icon-end {
    padding: 10px;
    height: 51px;
    width: 51px;
    text-align: center;
    background-color: $grey100;
    font-size: 1.6rem;
    color: $grey500-3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    height: 51px;
    border: 0;
    width: 100%;
    text-indent: 10px;

    &:focus {
      outline: none; // remove default outline
    }
  }

  // input[type="password"] {
  //     font-size: 2rem;
  // }
}
