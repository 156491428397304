@import 'scss/variables.scss';

.dropdown {
  position: relative;
  background-color: #fff;
  width: 100%;
  cursor: pointer;
  user-select: none;

  display: grid;
  align-items: center;

  .dropdown-toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    border: 2px solid $grey300-2;
    font-size: 14px;

    &.active {
      border-color: #5e5e5e;
    }

    &.disabled {
      background-color: #f1f1f1;
    }

    .placeholder {
      color: $grey500-3;
    }

    i {
      &:first-child {
        color: $grey700-4;
      }

      &:last-child {
        color: $grey500-3;
      }
    }

    &.select-lg {
      height: 55px;

      i {
        font-size: 1.5rem;
      }
    }

    &.select-md {
      height: 4rem;

      i {
        font-size: 1.5rem;
        height: 22px;
        width: 12px;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    overflow-y: auto;
    font-size: 14px;
    z-index: 2;
    background: white;
    border: 2px solid $grey300-2;
    padding: 0;
    margin: 0;
    margin-top: 0;
    max-height: 340px;
    border-top: 0;

    position: absolute;

    &.hidden {
      display: none;
    }

    label {
      cursor: pointer;
    }

    > * {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;

      i {
        font-size: 14px;
      }

      &:hover {
        background-color: $blue2-light-hover;
      }

      @for $i from 1 through 3 {
        &.indent-#{$i} {
          padding-left: 50px * $i + 50px;
        }
      }
    }

    &.select-lg {
      top: 55px;

      > * {
        // height: 55px;
        padding: 5px;
      }
    }

    &.select-md {
      top: 40px;

      > * {
        min-height: 40px;
        padding: 5px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: unset;
      }
    }
  }
}
