@import 'scss/variables.scss';

.modal {
  .modal-content {
    padding: 20px 50px 50px 50px;
  }

  .holder {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .text {
    width: 100%;
    margin: 0;
    margin-bottom: 50px;

    p {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
  }

  .notes {
    margin-top: 30px;

    textarea {
      padding: 10px;
    }
  }

  .question {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .error {
    margin: 15px 0;
    color: #e2234b;
    font-size: 1.4rem;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }
  // padding: 50px;
  // min-width: max-content;
  // min-height: max-content;
  // max-height: 600px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // text-align: center;

  // .modal-heading {
  //     font-size: 2.4rem;
  //     font-weight: 500;
  //     text-align: center;
  //     grid-column: 1/-1;
  // }

  // .modal-footer {
  //     display: flex;
  //     justify-content: space-around;
  //     flex-direction: row;
  // }
  // .create {
  //     align-self: center;
  // }
}
