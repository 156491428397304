@import 'scss/variables.scss';

.date-input {
  display: flex;
  justify-content: space-between;
  border: 2px solid $grey300-2;
  min-width: 160px;

  &.active {
    border-color: #5e5e5e;
  }

  .date-input-icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $grey100;
    padding: 0px 12px;
  }

  // input {
  input[type='date'] {
    text-transform: uppercase;
    border: 0;
    background-color: white;
    font-size: 1.3rem;
    content: attr(placeholder);
    width: 100%;
    text-indent: 5px;
    padding-right: 5px;
  }

  input[type='date']::-webkit-inner-spin-button {
    margin: auto 5px;
  }

  label {
    display: flex;
  }

  input[type='date']::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
  }

  &.date-input-full {
    width: 95%;
    .date-input-icon {
      font-size: 2rem;
    }
    input[type='date'] {
      height: 51px;
    }
  }

  &.date-input-lg {
    .date-input-icon {
      font-size: 2rem;
    }
    input[type='date'] {
      height: 51px;
    }
  }

  &.date-input-md {
    .date-input-icon {
      font-size: 1.6rem;
    }
    input[type='date'] {
      height: 36px;
    }
  }
}
