@import 'scss/variables.scss';

.title {
  display: flex;
  margin: 20px 0;
  h3 {
    font-weight: 500;
    font-size: 2.5rem;
    margin: 0;
  }
}

.formWrapper {
  container-type: inline-size;
  margin: 0 auto 20px;
}

.form {
  display: flex;
  flex-direction: row;

  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
  }

  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
  }

  .full {
    width: 100%;
    padding: 20px;
  }

  grid-column-gap: 60px;

  @container (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }

  .contact-phone-input {
    width: 100%;
  }
}

.notes {
  resize: none;
  height: 100px;
  padding: 10px;
  width: 50%;
}

.hr {
  color: $grey100;
  margin-top: 50px;
  margin-bottom: 25px;
}

.form-options {
  grid-column: 1/-1;
  justify-content: flex-end;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: max-content max-content;
}

@container (max-width: 850px) {
  .full {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .notes {
    width: 100%;
  }
}
