@import 'scss/variables.scss';

.user-email-addresses {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 20px;

  .error {
    border-color: #e2234b !important;
  }

  .error-msg {
    margin: 15px 0;
    color: #e2234b;
    font-size: 1.4rem;
  }

  .email-addresses {
    margin-top: 10px;
    flex-basis: 100%;
    padding: 20px;
    resize: none;
    border: 0.14em solid $grey300-2;
    color: $grey900-5;
    font-size: 1.6rem;
    max-height: 750px;
  }

  // Italic placeholder
  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }
}
