@import 'scss/variables.scss';

.modal {
  .modal-content {
    padding: 50px;
  }

  .holder {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .question {
    margin-bottom: 20px;
    text-align: justify;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }
}

.task,
.video,
.document,
.question {
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* Elevation / 03 */

  width: 100%;

  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 3px;

  min-width: 960px;
  max-width: 960px;
  padding: 40px;
  padding-bottom: 60px;

  color: #636366;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1000px) {
    min-width: 760px;
    max-width: 760px;
  }
  @media (max-width: 800px) {
    min-width: 90%;
    max-width: 90%;
  }
}

.question {
  & > div {
    min-width: auto;
    max-width: 640px;
    margin: auto;
  }
  h3 {
    color: #333;
    font-weight: 500;
  }
}

.document {
  max-width: 100%;
  width: 100%;
  padding: 0;
}

.video {
  width: 100%;
  padding: 0;
}

.task {
  padding: 48px;
}

.answers {
  display: flex;
  flex-direction: column;

  & > label {
    height: 45px !important;
  }
}

.task_holder {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin: 0 auto;
  margin-top: 20px !important;
  margin-bottom: 30px;
  min-width: auto;

  @media (max-width: 1000px) {
    min-width: auto;
    max-width: 760px;
  }
  @media (max-width: 800px) {
    min-width: auto;
    max-width: 90%;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #333;
  }

  .notes {
    font-weight: 400 !important;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
  }

  & > div {
    display: flex;

    & > div {
      line-height: 24px;
      margin-bottom: 16px;

      &:first-of-type {
        min-width: 120px;
        font-weight: 500;
        margin-right: 20px;
      }
    }
  }
}

.title_holder {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 640px;
  margin-bottom: 20px;

  h3 {
    font-weight: 500;
    font-size: 24px;
    color: #333;
    margin: 10px auto;
  }

  p {
    margin: 10px auto 20px auto;
    text-align: center;
    line-height: 28px;
  }
}

.icon {
  justify-content: center;
  display: flex;
  font-size: 48px;
  color: #34c759;
  margin: 10px;
}

.error {
  color: #ff0034;
}

// p {
//     font-size: 18px;
//     color: #333;
//     font-weight: 400;
//     text-align: center;
// }

.viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
    width: 100%;
  }
}

.textarea {
  padding: 10px;
}
