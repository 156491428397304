@import 'scss/variables.scss';

.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  max-width: 50%;
  margin-top: 100px;

  &.show {
    transform: translateX(0px);
    opacity: 1;
    transition: 0.8s cubic-bezier(0.14, 0.59, 0.55, 1.31);
    visibility: visible;
  }

  .modal-content {
    padding: 50px;
    text-align: center;
    overflow-y: scroll;
    max-height: 400px;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-warning {
    color: #d1123a;
    margin: 0 0 10px 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }

  .textarea {
    padding: 10px;
    resize: vertical;
    border: 2px solid #e0e0e0;
  }
}

.error-message-main {
  font-size: 18px;
}

.error-messages {
  .error-title {
    margin: 7px 0;
  }

  .error-message {
    margin: 5px 0;
  }
}
