.spinner-wrapper {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  min-height: 300px;
  width: 100%;

  @keyframes customLoad {
    0% {
      transition-property: opacity;
      opacity: 0;
    }
    30% {
      transition-property: opacity;
      opacity: 0;
    }
    100% {
      transition-property: opacity;
      opacity: 1;
    }
  }

  .spinner {
    width: 24px;
    animation: 300ms ease-out 0s 1 customLoad;
  }
}
