@import 'scss/variables.scss';

.login-panel-content {
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  grid-template-columns: minmax(450px, max-content);

  .welcome {
    font-size: 2rem;
  }
}

.login-panel-heading {
  height: 100px;
  padding: 20px;
  background-color: $grey100;
  box-sizing: border-box;

  .logo {
    width: 350px;
  }
}

.login-panel-footer {
  padding: 20px 0;
  height: 120px;
  background-color: $grey100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid $grey200-1;
}

.copyright {
  position: absolute;
  bottom: 15px;
  color: white;
  opacity: 0.5;
  font-size: 1.4rem;
}

.captcha {
  margin: auto;
}
