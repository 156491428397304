.mfa-title {
  font-size: 2rem;
  margin-top: 12px;
  margin-bottom: 0;
  letter-spacing: 0.6px;
}

.mfa-desc {
  margin-top: 10px;
  line-height: 1.5;
}
