@import 'scss/variables.scss';

.wrapper {
  max-width: 1140px;
  min-width: 480px;
  margin: 0 auto;

  @media (max-width: 767px) {
    margin: 0 32px;
  }
  @media (max-width: 720px) {
    padding-top: 15px;
  }
}

.loader {
  position: relative;
  div {
    position: absolute;
    z-index: 1;
  }
}

.main_title {
  margin: 35px 0 10px;
  font-size: 2rem;
  font-weight: 500;
}

.main_text {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $grey300-2;
  padding-top: 15px;
  padding-bottom: 20px;
}

.type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $grey300-2;
}
