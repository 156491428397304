$subtext-color: #636366;

.linechart {
  display: flex;
  background-color: white;
  padding: 30px;
  width: 100%;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  user-select: none;

  .isLink {
    cursor: pointer;
  }

  .line {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    .line_holder {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 5px;

      & > span:first-of-type {
        font-size: 16px;
      }
    }

    .line_graph {
      height: 6px;
      border-radius: 6px;

      span {
        display: flex;
        width: 0;
        height: 6px;
        border-radius: 6px;
      }
    }
  }

  .holder {
    display: flex;
    width: 100%;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
      font-weight: 500;
      font-size: 24px;
      margin: 0 0 5px 0;
    }

    p {
      margin: 0;
      color: $subtext-color;
      min-height: 50px;
      font-size: 14px;
      line-height: 1.4;
      max-width: 200px;
    }
  }

  .left {
    width: 70%;
  }
}

.percent {
  font-size: 18px;
  font-weight: 500;
}

.line_inner {
  display: flex;
  align-items: center;
}
