@import 'scss/variables.scss';

.site-select-panel {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  padding-bottom: 40px;

  .form-org-select,
  .form-site-select {
    max-width: 80%;
    margin: 20px auto;
  }

  .welcome {
    font-size: 2rem;
  }
}

.login-panel-heading {
  height: 100px;
  padding: 20px;
  background-color: $grey100;
  box-sizing: border-box;

  .logo {
    width: 350px;
  }
}

.login-panel-footer {
  padding: 20px 0;
  height: 120px;
  background-color: $grey100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid $grey200-1;
}

.copyright {
  position: absolute;
  bottom: 15px;
  color: white;
  opacity: 0.5;
  font-size: 1.4rem;
}
