@import 'scss/variables.scss';

.drag-n-drop {
  cursor: pointer;

  .drag-icon {
    font-size: 5rem;
  }

  .dnd-paragraph {
    font-size: 16px;
  }

  .line {
    display: inline-block;
    width: 25px;
    margin: 0;
    vertical-align: middle;
    border: 1px solid $grey500-3;
  }

  color: $grey500-3;
  margin: 25px auto 0 auto;
  // padding: 40px;
  width: 80%;
  // display: grid;
  align-items: center;
  justify-content: center;
  // grid-gap: 20px;
  background-color: $grey100;
  border: 2px dashed $grey300-2;

  &:hover {
    background-color: $grey200-1;
    border-color: $grey500-3;
  }

  &.disabled:hover {
    background-color: $grey100;
    border-color: $grey100;
    cursor: default !important;
  }

  .browse {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 240px;
    align-items: center;
    cursor: inherit;

    input[type='file'] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 240px;
      left: 0px;
      cursor: inherit;
    }
  }
}

.filesHolder {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 10px auto 0 auto;

  padding: 5px 10px;
  border: 1px solid #e3e3e3;
  background-color: #fbfbfb;
  max-height: 320px;
  overflow-y: auto;
}

.fileItem {
  margin-top: 5px;
  padding: 5px 5px 5px 15px;
  margin-bottom: 5px;
  font-size: 14px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  border: 1px solid #e3e3e3;
  align-items: center;
  user-select: none;
  background-color: white;

  .name {
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 340px;
    align-items: center;
  }

  .size {
    margin-left: 20px;
    color: #8b8b8b;
  }

  .perc {
    margin-left: auto;
    max-width: 350px;
    text-align: right;
    font-size: 12px;
  }

  .error {
    color: #e2234b;
  }

  .success {
    color: $green1-dark;
  }

  .remove {
    padding: 10px 5px;
    // color: white;
    color: #555555;
    // background-color: #e2234b;
    margin-left: 10px;
    cursor: pointer;
    width: 32px;
    font-size: 16px;

    &:hover {
      color: #e2234b;
    }
    &:active {
      color: #c01a3e;
    }
  }
}

.selected_title {
  text-align: left;
  width: 80%;
  margin-top: 20px;
  padding: 0;
  font-size: 16px;
  color: $grey500-3;
  text-transform: uppercase;
  font-weight: 500;
}

.supported {
  margin-top: 20px;
  font-size: 13px;
  line-height: 18px;
  color: #333;
}
