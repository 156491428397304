@import 'scss/variables.scss';

div.modal {
  height: auto;
  // margin: 100px auto 0 auto;
  .modal-header,
  .modal-footer {
    background-color: $grey200-1;
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }

  .modal-body {
    color: $grey500-3;
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    select {
      display: inline-block;
    }

    .existing-folder {
      display: grid;
      // grid-template-columns: 1fr 2fr;
      width: 100%;
      grid-gap: 20px;

      menu.parent-folder-select:hover {
        background-color: red;
      }

      .folder-name {
        padding: 1rem;
      }

      .parent-folder {
        list-style: none;
        display: grid;
        text-align: left;
        height: 100%;
        padding: 30px;
        grid-gap: 5px;

        &:hover {
          background-color: white;
        }

        .search-input {
          margin-bottom: 20px;
        }

        li.folder {
          display: grid;
          grid-template-columns: max-content 1fr;
          grid-column-gap: 10px;
          color: $grey700-4;
          align-items: center;
          padding-top: 8px;
          padding-bottom: 8px;

          i {
            font-size: 2.4rem;
          }

          &:hover {
            background-color: #e8f6fc;
          }
        }
        @for $i from 0 through 10 {
          .depth-#{$i} {
            padding-left: #{(($i) * 30 + 8)}px;
          }
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }

  .heading-line {
    padding: 0 0 30px 0;
  }
}
