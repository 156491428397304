@import 'scss/variables.scss';

.search {
  display: flex;
  align-items: center;
  border: 2px solid $grey300-2;
  position: relative;

  .search-input {
    height: 100%;
    width: 100%;
    padding: 0 5px 0 10px;
    background-color: white;
    border: 0;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.05rem;
  }

  i {
    background: $grey200-1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey700-4;
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    right: -46px;
    width: 46px;
    height: 48px;
    border: 2px solid $grey300-2;
  }

  &.search-lg {
    height: 55px;

    .search-input {
      height: 51px;
    }

    i {
      width: 55px;
      height: 51px;
      font-size: 2rem;
    }
  }

  &.search-md {
    .search-input {
      height: 36px;
    }
    height: 40px;
    i {
      right: -40px;
      width: 40px;
      height: 40px;
    }
  }
}
