.drawer {
    display: grid;
    grid-template-columns: 260px 1fr;
    transition: grid .2s ease-in;
    box-sizing: border-box;
    height: auto;

    @media (max-width: 769px) {
        display: block;     
    }

    &.drawer-items-collapsed {
        grid-template-columns: 65px 1fr;
        @media (max-width: 769px) {
            grid-template-columns: 0 1fr;            
        }
    }

    .drawer-items {        
        width: 260px;
        background-color: white;
        justify-items: center;
        position: relative;
        z-index: 9;
        transition: width .2s ease-out;

        @media (max-width: 769px) {
            display: block;
            position: absolute;
            top: 0;
            left: 0;     
        }

        .items-holder {
            height: 100vh;
            width: inherit;
            border-right: 2px solid #eeeeee;
        }

        &.drawer-items-collapsed {
            width: 65px;
            transition: width .2s ease-out;

            @media (max-width: 769px) {
                .items-holder {
                    visibility: hidden;
                    transition: visibility .2s ease-out;
                }
                width: 0;
                transition: width .2s ease-out;
            }
        }
    }

    .drawer-other {
        background-color: #fafafa;
        position: relative;
        z-index: 6;
        overflow: hidden;

        .overlay {
            display: none;
            cursor: pointer;
        }
        @media (max-width: 769px) {
            .overlay {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 8;
                height: 100vh;
                width: 100%;
                background-color: rgba(0,0,0,0.6);
            }      
        }

        &.drawer-items-collapsed {
            @media (max-width: 769px) {
                .overlay {
                    display: none;
                }        
            }
        }
    }
}
