@import 'scss/variables.scss';

.alert {
  position: fixed;
  padding: 17px;
  min-width: 80%;
  max-width: max-content;
  min-height: auto !important;
  background: #123456;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 9999;
  border-radius: 5px;
  background-color: #e0f1cb;
  transition: 0.8s cubic-bezier(0.14, 0.59, 0.55, 1.31);
  opacity: 0;
  visibility: hidden;
  cursor: pointer;

  &::after {
    content: '✖';
    position: fixed;
    right: 15px;
    font-size: 14px;
  }

  &.show {
    transform: translateY(130px);
    opacity: 1;
    transition: 0.8s cubic-bezier(0.14, 0.59, 0.55, 1.31);
    visibility: visible;
  }

  &.primary {
    background-color: $blue2-light-hover;
    color: $blue1-byRoland;
    border: 1px solid $blue1-byRoland;
  }

  &.danger {
    background-color: $red1-light-error-notifications;
    color: $red1;
    border: 1px solid $red1;
  }

  &.success {
    background-color: #e0f1cb;
    border: 1px solid #7eb239;
    color: #679031;
  }
}
