@import 'scss/variables.scss';

.add-note {
  margin: 20px 0;

  .add-note-heading {
    font-size: 2.4rem;
    margin: 0;
  }

  hr {
    color: $grey100;
    margin: 10px auto 30px auto;
  }

  .add-note-data {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-column-gap: 20px;
    grid-row-gap: 25px;
    align-items: flex-start;

    @media (max-width: 840px) {
      display: flex;
      flex-direction: column;
    }

    .note_holder {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      textarea {
        padding: 10px;
        resize: vertical;
      }
    }

    .add-note-button {
      margin-left: 10px;
      margin-top: 30px;
      margin-bottom: auto;

      i {
        font-weight: bold;
      }

      &.add-note-button-center {
        align-self: center;
      }
    }

    .add-note-table {
      grid-column: 1/-1;
      grid-row: 2/3;
      margin: 0;
      width: 100%;
    }
  }

  .confirmation-modal {
    padding: 50px;
    min-width: max-content;
    min-height: max-content;
    max-height: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    align-items: center;

    .confirmation-modal-heading {
      font-size: 2rem;
      font-weight: 500;
      text-align: center;
      grid-column: 1/-1;
    }
  }

  .error-msg {
    color: #e2234b;
  }
}
