@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

@import 'variables.scss';
@import 'header.scss';
@import 'data-table.scss';

:root {
  font-size: 62.5%;
}

body,
input,
textarea,
select,
button {
  font-family: $font-family-1;
  font-size: $font-size-normal;
  font-weight: $weight-normal;
  color: $color-text-normal;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

// Fix to stretch background height as per https://stackoverflow.com/questions/2869212/css3-gradient-background-set-on-body-doesnt-stretch-but-instead-repeats
html {
  height: auto;
  min-height: 100%;
}
body {
  height: auto;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body,
#root,
#root > div {
  min-height: 100vh;
}
