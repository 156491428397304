@import 'scss/variables.scss';

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 25px;
  margin: 20px auto;

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  // So an error in one formElement doesn't misplace the contents of another
  > * {
    align-content: flex-start;
  }

  .add-task {
    grid-column: 1/-1;

    :global(.data-table-tasks) {
      margin: 0;
    }
  }

  .notes {
    resize: none;
    height: 100px;
    padding: 10px;
    background-color: white;
    border: 2px solid $grey300-2;
    // As is in the design, I prefer it without
    // width: calc(100% + 100px);
  }

  .hr {
    grid-column: 1/-1;
    color: $grey100;
    margin-top: 50px;
  }

  .form-options {
    grid-column: 1/-1;
    justify-content: flex-end;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: max-content max-content;
  }
}
