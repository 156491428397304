.create {
  align-self: center;
  margin-left: 20px;
}

.siteAll {
  display: flex;
  width: 100%;
}

.holder {
  display: flex;
  align-items: flex-start;
}

.launch-date {
  margin-left: 20px;
}

.complete {
  display: flex;
  margin-left: -5px;
}
