@import 'scss/variables.scss';

.add-evidence {
  margin-top: 20px;

  .add-evidence-heading {
    font-size: 2.4rem;
    margin: 0;
  }

  hr {
    color: $grey100;
    margin: 10px auto 30px auto;
  }

  .add-evidence-data {
    display: flex;
    flex-direction: column;

    .holder {
      display: flex;
      margin-bottom: 20px;
      // justify-content: flex-start;
      // align-items: center;
      // margin-bottom: 8px;
      // min-height: 80px;
    }

    .button {
      margin-top: 28px;
      margin-left: 16px;
    }

    .add-evidence-table {
      grid-column: 1/-1;
      grid-row: 2/3;
      margin: 0;
      width: 100%;
    }
  }

  .confirmation-modal {
    padding: 50px;
    min-width: max-content;
    min-height: max-content;
    max-height: 350px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    align-items: center;

    .confirmation-modal-heading {
      font-size: 2rem;
      font-weight: 500;
      text-align: center;
      grid-column: 1/-1;
    }
  }
}
