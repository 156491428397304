@import 'scss/variables.scss';

.modal {
  .modal-content {
    padding: 50px;
  }

  .holder {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }
}
