@import 'scss/variables.scss';

.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(0, max-content));

  .breadcrumb {
    font-size: 1.6rem;

    .separator {
      margin: 0 6px;
    }
  }

  // Get all breadcrumb links, exclude breadcrumb-current, which shouldn't be a link anyway
  a.breadcrumb:not(.breadcrumb-current) {
    color: $blue1-byRoland;
    text-decoration: underline;
  }
}
