@import 'scss/variables.scss';

.choose-action {
  position: relative;
  margin-right: 15px;

  .choose-action-button {
    line-height: 38px;

    i {
      vertical-align: text-top;
      margin-left: 10px;
      margin-right: 0px;
      line-height: 12px;
    }
  }

  .actions {
    position: absolute;
    background-color: white;
    width: 200px;
    top: 50px;
    padding: 0;
    @include boxshadow(1);
    font-size: 1.6rem;
    color: $grey700-4;
    transition: all 0.1s;
    z-index: 2;

    &.hide {
      opacity: 0;
      visibility: hidden;
      transition: all 0.1s;
    }

    .action {
      cursor: pointer;
      padding: 20px;
      user-select: none;

      font-size: 14px;

      &:hover {
        background-color: $grey100;
      }
    }
  }
}
