.holder {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  padding: 20px 20px 40px 20px;
  flex: auto;
}

.panels {
  // background-color: red;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  display: grid;
  column-gap: 40px;
  row-gap: 40px;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;

  @media (max-width: 920px) {
    grid-template-columns: 1fr;
  }
}

.stats {
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  display: grid;
  column-gap: 40px;
  row-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 40px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.boxes {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
