@import 'scss/variables.scss';

.dropdown {
  position: relative;
  z-index: 10;

  .config-button {
    padding: 5px;
    &:hover {
      box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
      background: #f2f2f7;
      cursor: pointer;
    }
    &.active {
      box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
      background: #f2f2f7;
      cursor: pointer;
    }
    .config-button-cog {
      margin-right: 10px;
    }
    .config-button-sort-down {
      position: relative;
      bottom: 2px;
    }
  }

  .menu {
    position: absolute;
    top: 30px;
    right: 0px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 3px;
    font-family: $font-family-1;

    .menu-header {
      border-bottom: 1px solid #f2f2f7;
      padding: 10px;
      width: 250px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #8e8e93;
    }

    .menu-body {
      height: 313px;
      overflow-y: scroll;

      .checkbox {
        display: flex;
        padding-left: 0px !important;
        margin: 10px;
      }
    }
  }
}
