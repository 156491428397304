.holder {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
  background-color: #fff;
}

.content {
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;

  & > div {
    width: -webkit-fill-available;
  }
}
