.org-wrapper {
  margin-bottom: 50px;
  color: #757575;
  line-height: 22px;
  height: 600px;
  overflow-y: scroll;

  strong {
    color: #666666;
  }

  .org {
    margin-bottom: 20px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #21a9e1;
    margin-bottom: 20px;
  }

  ul {
    margin: 0;
    li {
      list-style-type: none;
    }
  }

  .sites {
    margin-left: 30px;
  }

  .divider {
    margin-top: 10px;
    border-top: 1px dotted #757575;
    height: 20px;
    width: 30%;
  }
}

.org-site-bottom-buttons {
  display: flex;
  justify-content: space-around;
}

.org-setup-error-message {
  color: #e2234b;
  font-size: 1.4rem;
  text-transform: lowercase;
  margin: 10px 0;
  text-align: center;
}

.org-setup-error-message::first-letter {
  text-transform: uppercase;
}

.org-setup-success-message {
  color: #34c759;
  font-size: 3rem;
  text-transform: lowercase;
  text-align: center;
  padding-top: 40px;

  .save-success-icon {
    margin-top: 20px;
    font-size: 6rem !important;
  }
}

.org-setup-success-message::first-letter {
  text-transform: uppercase;
}
