.wrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.tabs {
  display: flex;
  padding: 14px 30px 0;
  background-color: #E9F6FC;
}

.tab {
  padding: 14px 30px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
  &:hover {
    color: #21AAE1;
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.tabActive, .tabActive:hover {
  color: #21AAE1;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.content {
  background-color: #ffffff;
  //padding: 20px 30px;
}