@import 'scss/variables.scss';

.page-header-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &.collapsed {
    @media (max-width: calc(1610px + $collapsed)) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &.expanded {
    @media (max-width: calc(1610px + $expanded)) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .page-header-left {
    display: flex;
    // flex-wrap: wrap;
    // gap: 15px;

    & > * {
      margin-right: 15px;
      // flex-grow: 0;
      // flex-shrink: 1;
      // @media only screen and (max-width: 417px) {
      //     flex-grow: 1;
      // }
    }

    .search {
      margin-left: auto;
      margin-right: 48px;
      width: 120px;
      // margin-right: 38px;

      // @media (max-width: 1500px) {
      //     width: 200px;
      // }
      &.collapsed {
        @media (max-width: calc(540px + $collapsed)) {
          display: none;
        }
      }

      &.expanded {
        @media (max-width: calc(640px + $expanded)) {
          display: none;
        }
      }
    }

    .search:last-child {
      width: 120px;
      margin-right: 38px;
    }
  }

  .filters-dropdown {
    background: red;
  }

  .page-header-right {
    display: flex;
    align-items: center;
    &.collapsed {
      @media (max-width: calc(1610px + $collapsed)) {
        margin-bottom: 20px;
      }
      @media (max-width: calc(955px + $collapsed)) {
        display: none;
      }
    }

    &.expanded {
      @media (max-width: calc(1610px + $expanded)) {
        margin-bottom: 20px;
      }
      @media (max-width: calc(955px + $expanded)) {
        display: none;
      }
    }
  }

  .page-header-filters {
    display: flex;
    margin-left: auto;
    width: 100%;
    align-items: flex-end;
    position: relative;

    > *:not(last-child) {
      margin-right: 10px;
    }

    .filter-icon {
      position: absolute;
      left: -25px;
      bottom: 12px;
      color: $grey500-3;
    }

    .role-filter,
    .type-filter,
    .last-action-filter,
    .status-filter {
      min-width: 150px;
    }

    .site-filter {
      min-width: 180px;
    }

    .reset-filters {
      height: 40px;
      width: 40px;
      min-width: 40px;
      background: $grey300-2;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;
      margin-right: 0;

      &:hover {
        background: $grey500-3;
        color: white;
      }
    }
  }
}
