@import "scss/variables.scss";

.add-remove-items {
    display: grid;
    grid-template-columns: 1fr 100px 1fr;
    grid-template-rows: 1fr;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */

    .items-title {
        color: #444444;
        font-size: 1.5rem;
        letter-spacing: 0.05rem;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
    }

    .item {
        list-style-type: none;
        text-align: left;
        padding: 4.5px 0;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        cursor: pointer;
    }

    .item-removed {
        display: none;
    }

    .item-selected {
        background-color: #d4ebf2;
    }

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .all-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;

        .search {
            display: flex;
            align-items: center;
            border: 2px solid $grey300-2;
            position: relative;
            width: calc(100% - 38px);
            margin-bottom: 2px;
    
            .search-input {
                height: 100%;
                width: 100%;
                padding: 0 5px 0 10px;
                background-color: white;
                border: 0;
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.05rem;
            }
    
            i {
                background: $grey200-1;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $grey700-4;
                font-weight: 500;
                cursor: pointer;
                position: absolute;
                right: -46px;
                width: 46px;
                height: 48px;
                border: 2px solid $grey300-2;
            }
    
            &.search-md {
                .search-input {
                    height: 36px;
                }
                height: 40px;
                i {
                    right: -40px;
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .all-items-data {
            color: #212121;
            font-size: 14px;
            font-weight: 400;
            box-sizing: border-box;
            height: 159px;
            display: flex;
            align-items: flex-start;
            border-radius: 0%;
            border: 2px solid #e0e0e0;
            min-width: 0;
            text-indent: 1.2rem;
            background-color: white;
            overflow-y: scroll;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 0 20px;

        .button {
            background-color: white;
            border: 1px solid #9e9e9e;
            cursor: pointer;
        }
    }

    .added-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;

        .added-items-data {
            color: #212121;
            font-size: 14px;
            font-weight: 400;
            box-sizing: border-box;
            height: 212px;
            display: flex;
            align-items: flex-start;
            border-radius: 0%;
            border: 2px solid #e0e0e0;
            min-width: 0;
            text-indent: 1.2rem;
            background-color: white;
            overflow-y: scroll;
        }
    }

    .disabled {
        background-color: #f1f1f1 !important;
    }

    button:disabled,
    button[disabled]{
        background-color: #f1f1f1 !important;
    }

    input:disabled,
    input[disabled]{
        background-color: #f1f1f1 !important;
    }

    .error-message {
        @include error-message;
    }

    .small-text {
        margin-left: 6px;
        font-size: 1.4rem;
        color: $grey500-3;
        font-weight: 500;
      }
}
