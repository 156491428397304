@import 'scss/variables.scss';

$subtext-color: #636366;

.isLink {
  cursor: pointer;
}

.pie-chart-box {
  display: flex;
  width: 100%;
  background-color: white;
  margin-right: 30px;
  min-width: 200px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  user-select: none;
  height: 100%;

  .holder {
    display: flex;
    padding: 30px;
  }

  .text {
    max-width: 200px;
    color: $subtext-color;
    line-height: 2rem;
  }

  h2 {
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 5px 0;
  }

  .desc {
    min-height: 50px;
    font-size: 14px;
    line-height: 1.4;
    min-width: 230px;
  }

  .circle {
    max-width: 180px;
    width: 65%;
    padding-top: 20px;

    @container (max-width: 1315px) {
      width: 150px;
    }

    .perc {
      font-size: 4rem;
      font-weight: 500;
      color: #21aae1;
    }
    .perc-text {
      font-size: 1.5rem;
      color: #777;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  .legend {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e3e3e3;
    padding-left: 30px;
    margin-left: 30px;
    width: 30%;
    font-size: 1.1rem;
    height: 100%;
    min-width: 180px;

    h1 {
      font-weight: 500;
      font-size: 32px;
      padding: 0;
      margin: 0;
    }

    .label {
      font-size: 1.3rem;
      color: $subtext-color;
    }

    .stats {
      position: relative;
      top: 30px;
      color: $subtext-color;
      font-size: 1.3rem;
    }
  }
}
