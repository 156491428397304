@import 'scss/variables.scss';

.upload-documents {
  container-type: inline-size;
  .subheading {
    font-size: 2rem;
    margin: 0;
  }

  .hr {
    border: 1px solid $grey300-2;
    margin: 10px 0 20px 0;
  }

  .upload-document-options {
    grid-template-columns: minmax(300px, max-content) 250px max-content auto 150px;
    grid-gap: 20px;
    align-items: flex-start;
    display: flex;
    margin-bottom: 8px;

    @container (max-width: 840px) {
      display: flex;
      flex-direction: column;
    }

    .upload-button {
      margin-top: 28px;
      padding: 0 30px;
      & > i {
        font-size: 18px;
        margin-right: 10px;
      }
    }

    .type-filter {
      grid-column: 5/6;

      .filter-icon {
        position: absolute;
        left: -25px;
        bottom: 20px;
        color: $grey500-3;
      }
    }
  }

  .upload-documents-table {
    margin: 30px auto;
  }
}
