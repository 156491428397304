// Standard Bootstrap container
.container {
  // Extra small devices (portrait phones, less than 576px)
  width: 100%;
  padding-top: 15px;
  padding-bottom: 100px;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 720px) {
    padding: 0;
  }

  &:not(.fluid) {
    // Small devices (landscape phones, less than 768px)
    @media (min-width: 576px) {
      width: 540px;
    }

    // Medium devices (tablets, less than 992px)
    @media (min-width: 768px) {
      width: 720px;
    }

    // Large devices (desktops, less than 1200px)
    @media (min-width: 992px) {
      width: 960px;
    }

    // Extra large by default
    @media (min-width: 1200px) {
      width: 1140px;
    }

    &.wide {
      @media (min-width: 1400px) {
        width: 1350px;
      }
      @media (min-width: 1650px) {
        width: 1600px;
      }
    }
  }

  &.fluid {
    &:not(.wide) {
      max-width: 1140px;
    }
    // &.wide {
    //     // max-width: 1600px;
    // }
  }
}

.dashboard {
  padding-top: 35px;
  padding-bottom: 25px;

  @media (max-width: 1200px) {
    margin: auto;
    width: 100% !important;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    // max-width: 1600px;
  }
}
