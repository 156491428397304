.stepper {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;

  @media (max-width: 992px) {
    font-size: 85%;
  }

  @media (max-width: 680px) {
    font-size: 65%;
  }

  .stepper-item {
    width: 100%;
    text-align: center;
    position: relative;
    font-weight: 500;

    &.completed {
      color: #21a9e1;
    }

    &.active {
      color: #757575;
      .stepper-circle {
        border-color: #757575;
        color: #757575;
      }
      .stepper-line {
        border-color: #757575;
      }
    }

    &.disabled {
      color: #dfdfdf;
      .stepper-circle {
        border-color: #dfdfdf;
        color: #dfdfdf;
      }
      .stepper-line {
        border-color: #dfdfdf;
      }
    }
  }

  .stepper-circle {
    position: relative;
    background-color: white;
    margin: auto;
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border: 3px solid #21a9e1;
    border-radius: 100px;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 5px;

    & > i {
      color: #21a9e1;
    }
  }

  .stepper-line {
    border-top: 3px solid #21a9e1;
    margin-top: -35px;
    margin-bottom: 35px;
  }

  @media (max-width: 992px) {
    .stepper-circle {
      width: 40px;
      height: 40px;
      font-size: 85%;
    }
    .stepper-line {
      border-top: 3px solid #21a9e1;
      margin-top: -25px;
      margin-bottom: 25px;
    }
  }
}
