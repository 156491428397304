@import 'scss/variables.scss';

.button {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 40px;
  letter-spacing: 0.12rem;
  // display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  min-width: 140px;

  &.cursor-default {
    cursor: default;
  }

  i + span {
    margin-left: 10px;
  }

  span + i {
    margin-left: 10px;
  }

  &.primary {
    border: 2px solid transparent;
    color: white;
    background-color: $blue1-byRoland;

    &:hover {
      background-color: $blue1-dark;
    }
    &:active {
      background-color: $blue3-dark !important;
    }
  }

  &.btn-primary-outline {
    color: $blue1-byRoland;
    background-color: white;
    border: 2px solid $blue1-byRoland;
    box-sizing: border-box;

    &:hover {
      background-color: white;
    }
  }

  &.secondary {
    color: $grey700-4;
    font-weight: 400;
    border: 2px solid $grey500-3;
    background-color: white;

    &:hover {
      background-color: white;
      border-color: $grey600;
    }
  }

  &.btn-lg {
    padding-left: 45px;
    padding-right: 45px;
    font-weight: 500;
    font-size: 1.6rem;
    height: 55px;
    // line-height: 53px;
    justify-content: center;
    display: flex;
  }

  &.btn-md {
    font-weight: 500;
    font-size: 1.4rem;
    padding-left: 25px;
    padding-right: 25px;
    height: 40px;
    line-height: 38px;
    // justify-content: center;
    // display: flex;
    @media (max-width: 840px) {
      font-size: 1.1rem;
      padding-left: 20px;
      padding-right: 20px;
      height: 40px;
      line-height: 38px;
    }
  }
  &.btn-disabled {
    background-color: #e3e3e3;
    // background-color: rgba(4, 80, 156, 0.4);
    cursor: default;
    // color: #575757;

    &:hover,
    &:active {
      background-color: #e3e3e3 !important;
    }
  }

  &.btn-disabled.primary {
    background-color: rgba(4, 80, 156, 0.4);

    &:hover,
    &:active {
      background-color: rgba(4, 80, 156, 0.4) !important;
    }
  }
}

.danger {
  color: white;
  background-color: $red1;
  border: 2px solid transparent;

  &:hover {
    background-color: #d1123a;
  }
}

.success {
  color: #34c759;
  border: 2px solid #34c759;
  background-color: white;
  text-align: center;

  &:hover {
    color: #34c759;
    border: 2px solid #34c759;
    background-color: white;
  }
}

.fail {
  color: #ff2d55;
  border: 2px solid #ff2d55;
  background-color: white;
  text-align: center;

  &:hover {
    color: #ff2d55;
    border: 2px solid #ff2d55;
    background-color: white;
  }
}

.warning {
  color: #ffc107;
  border: 2px solid #ffc107;
  background-color: white;
  text-align: center;

  &:hover {
    color: #ffc107;
    border: 2px solid #ffc107;
    background-color: white;
  }
}

.label {
  align-items: center;
  justify-content: center;
  background-color: #c7e9f7;
  color: #1c1c1e;
  border: 2px solid #c7e9f7;
  cursor: default;
}

.optional {
  color: #8e8e93;
  border: 2px solid #8e8e93;
  background-color: white;
  text-align: center;
}

// @mixin btn {
//     // letter-spacing: 2px;
//     cursor: pointer;
//     // display: grid;
//     // grid-auto-flow: column;
//     // grid-gap: 20px;
//     // align-items: center;
// }
// @mixin btn-lg {
//     // @include btn;
//     // font-size: 1.6rem;
//     // font-weight: bold;
//     // // line-height: 7rem;
//     // height: 7rem;
//     // padding: 2rem 5rem;
// }
// @mixin btn-md {
//     // @include btn;
//     // font-size: 1.5rem;
//     // font-weight: bold;
//     // height: 4rem;
//     // padding: 1rem 3rem;
// }
// @mixin btn-sm {
//     // @include btn;
//     // font-size: 1.4rem;
//     // height: 3rem;
//     // padding: 0.5rem 1rem;
// }

// @mixin btn-primary {
//     // color: white;
//     // background-color: $blue1-byRoland;

//     // &:hover {
//     //     background-color: $blue1-dark;
//     // }
// }

// @mixin btn-primary-outline {
//     // color: $blue1-byRoland;
//     // background-color: white;
//     // border: 2px solid $blue1-byRoland;
//     // box-sizing: border-box;

//     // &:hover {
//     //     background-color: white;
//     // }
// }

// @mixin btn-secondary {
//     // color: $grey700-4;
//     // border: 2px solid $grey500-3;
//     // background-color: white;
// }
// @mixin btn-danger {
//     // color: white;
//     // background-color: $red1;

//     // // &:hover {
//     // //     background-color: #d1123a;
//     // // }
// }

// @mixin btn-disabled {
//     // color: white;
//     // background-color: $grey500-3;
//     // cursor: default;
// }

// .button {
//     // border-radius: 50px;
//     // text-align: center;
//     // transition: all 0.2s;
//     // user-select: none;

//     &.primary {
//         @include btn-primary;

//         &:active {
//             background-color: $blue3-dark !important;
//         }
//     }

//     &.primary-outline {
//         @include btn-primary-outline;
//     }

//     &:hover {
//         background-color: $blue1-dark;
//     }

//     &.secondary {
//         @include btn-secondary;
//     }

//     &.danger {
//         @include btn-danger;
//     }

//     &.btn-lg {
//         @include btn-lg;
//     }

//     &.btn-md {
//         @include btn-md;
//     }

//     &.btn-sm {
//         @include btn-sm;
//     }

//     &.btn-disabled {
//         @include btn-disabled;
//         &:hover {
//             @include btn-disabled;
//         }
//     }
// }
