.statistics {
  width: 100%;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 124px;
  padding: 14px 0;
  background: #c7e9f7;
  color: #1c1c1e;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 3px;
  display: flex;

  .icon {
    width: 100px;
    position: relative;

    .icon__badge {
      position: absolute;
      top: -30px;
      left: 20px;
      background: #21aae1;
      height: 48px;
      width: 48px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      text-align: center;
      color: white;
      font-size: 20px;

      & > i {
        position: relative;
        top: calc(50% - 10px);
      }
    }
  }
  .page {
    width: 100%;

    .page-title {
      font-weight: 500;
      font-style: normal;
      font-size: 23px;
      line-height: 34px;

      @container (max-width: 480px) {
        font-size: 16px;
      }
    }

    .page-stats {
      display: flex;

      .divider {
        margin: 15px 10px;
        width: 60px;
        border-bottom: 1px solid #21aae1;
        align-self: center;

        @container (max-width: 480px) {
          width: 20px;
        }
      }
    }

    .page-stats__total {
      font-weight: 500;
      font-style: normal;
      font-size: 43px;
      line-height: 64px;
      display: inline-block;

      @container (max-width: 480px) {
        font-size: 32px;
      }
    }

    .page-stats__title {
      display: inline-block;

      small {
        display: block;
        font-weight: 400;
        font-size: 14px;
        margin-left: 10px;
        color: #636366;

        @container (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }
}
