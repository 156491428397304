.password-error {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-top: -20px;

  .label {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .list {
    padding: 0 0px;

    li {
      list-style-type: none;
    }

    svg {
      position: relative;
      top: 4px;
    }

    i {
      min-width: 26px;
    }

    .correct {
      color: #34c759;
    }

    .incorrect {
      color: #e2234b;
    }
  }
}
