@import 'scss/variables.scss';

.page-header {
  max-height: 500px;
  height: 150px;
  // transition: 0.2s all;
  display: grid;
  align-items: flex-start;

  &.show-description {
    height: auto;
  }

  .heading {
    font-size: 2.4rem;
    margin: 0;
    font-weight: 500;
    color: $grey900-5;

    .heading-icon {
      margin-left: 2rem;
      background-color: $grey200-1;
      padding: 10px;
      color: $grey500-3;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        background-color: $grey700-4;
        color: $grey100;
        transition: all 0.2s;
      }

      &.active {
        background-color: $blue1-byRoland;
        color: white;

        &:hover {
          background-color: $blue2;
        }
      }
    }
  }
}

.question_holder {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  display: flex;
  width: 420px;
  max-width: 420px;
  margin-right: 40px;
  margin-left: auto;
}

.buttons {
  margin: 40px 50px;
  display: flex;
  // justify-content: space-between;
  // align-items: center;
}

.confirmation-modal {
  padding: 50px;
  min-width: max-content;
  min-height: max-content;
  max-height: 350px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  align-items: center;

  .confirmation-modal-heading {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    grid-column: 1/-1;
    display: grid;
    grid-gap: 20px;
  }

  .confirmation-modal-warning {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    color: $red1;
    grid-column: 1/-1;
  }

  .confirmation-modal-buttons-container {
    grid-column: 1/-1;
    display: flex;
    justify-content: space-around;
  }
}

.modal {
  padding: 0px;
  min-width: max-content;
  min-height: max-content;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .create {
    align-self: center;
    margin-left: 20px;
  }

  .modal-content {
    padding: 50px;
    display: flex;

    & > div {
      width: 100%;
      padding: 20px;
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }
}

.button {
  margin: 0 20px;
}

.finalize {
  margin-left: auto;
}

.finalized {
  color: white !important;
  background-color: $red1 !important;
}

.total {
  font-size: 14px;
  color: #7e7e7e;
  margin: 0px 50px;
  align-items: center;
  display: flex;
}
