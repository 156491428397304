@import 'scss/variables.scss';

.login-panel-wrap {
  min-height: 100vh;
  // TODO: FIX use below
  // @include body-gradient-screen;
  background: rgb(10, 64, 126);
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 100% 0, #23a8e1 0%, #0a407e 100%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .login-banner {
    // background-color: #e8f0fd;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    margin-bottom: auto;
    padding: 20px;

    p {
      font-style: italic;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      max-width: 680px;
      margin: auto;
    }
  }

  .login-panel {
    text-align: center;
    min-width: 800px;
    max-width: 1000px;
    min-height: 560px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // margin-top: 50px;
    margin-top: auto;
    margin-bottom: auto;

    @include boxshadow(3);
    z-index: 1000;

    .login-panel-heading {
      height: 100px;
      padding: 20px;
      background-color: $grey100;
      box-sizing: border-box;

      .logo {
        width: 350px;
      }
    }

    .login-panel-footer {
      padding: 20px 0;
      height: 200px;
      background-color: $grey100;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border-top: 2px solid $grey200-1;
    }
  }

  .copyright {
    color: white;
    opacity: 0.5;
    font-size: 1.4rem;
    margin: 20px;
  }
}
