@import 'scss/variables.scss';

.page-header {
  background-color: $grey50;
  display: grid;
  border-bottom: 1px solid $grey300-2;

  .page-header-container {
    grid-template-columns: 1fr auto 1fr;
    padding: 20px 50px;
    display: flex;
    min-height: 80px;

    @media (max-width: 840px) {
      padding: 20px;
    }

    .show-stats {
      grid-column: 3/4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      margin-left: auto;

      @media (max-width: 992px) {
        display: none;
      }

      .show-stats-icon {
        font-size: 1.5rem;
        color: $grey700-4;
        transition: all 0.3s;

        &.active {
          transform: rotate(180deg);
          transition: all 0.3s;
        }
      }

      .chart-icon {
        margin-left: 10px;
        font-size: 2rem;
        background-color: $grey200-1;
        padding: 10px;
        color: $grey500-3;
        transition: all 0.3;

        &.active {
          background-color: $blue2;
          color: white;
          transition: all 0.3;
        }
      }
    }
  }

  .stats {
    background-color: $grey200-1;
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 25px;
    transition: height 0.2s, opacity 0.5s, padding 0.2s;
    opacity: 1;
    padding: 30px 50px;

    @media (max-width: 992px) {
      display: none;
    }

    &.hide-stats {
      height: 0;
      opacity: 0;
      padding: 0;
      transition: height 0.3s, opacity 0.2s, padding 0.3s;
    }
  }

  .page-header-children {
    padding: 20px 50px;
    z-index: 2;

    .heading {
      font-size: 2.4rem;
      color: $grey900-5;

      display: flex;
      justify-content: space-between;

      .hints {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        color: $blue1-byRoland;
        font-size: 1.4rem;
        cursor: pointer;

        span {
          text-decoration: underline;
        }
      }
    }
  }
}
