@import 'scss/variables.scss';

.loading-container {
  min-height: 5em;
  padding: 1em;

  > h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.fade-enter-active,
.fade-exit-active,
.fade-appear-active {
  transition: all 300ms;
}

.fade-enter,
.fade-appear,
.fade-exit.fade-exit-active {
  opacity: 0;
}

.fade-exit,
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  opacity: 1;
}

.animated-loading-transition-wrap {
  &.loading {
    position: relative;
    min-height: 150px;
  }

  .animated-loading-transition-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

// @media (max-width: 1200px) {
//     .data-table-wrap {
//         font-size: 0.85em;
//     }
// }

.data-table-wrap {
  background-color: white;
  font-size: 0.85em;
  margin: 0 50px;
  box-shadow: 0.25em 0.25em 1em 0 rgba(0, 0, 0, 0.2);
  // box-shadow: 0 0.2em 0.75em 0.1em rgba(0, 0, 0, 0.1);

  .title,
  .name {
    width: fit-content;
    min-width: 200px;
  }

  .col-status {
    width: 180px;
  }

  .col-type {
    min-width: 120px;
  }

  .col-site {
    min-width: 100px;
  }

  @media (max-width: 720px) {
    padding: 0;
    margin: 20px 10px;
  }

  .data-table-scrollbox {
    // position: relative;
    width: 100%;
    // overflow-y: hidden;
    overflow-x: auto;
    // overflow: visible;
    // z-index: 1;
    // min-height: 400px;
  }

  .data-table {
    display: table;
    width: 100%;
    border-collapse: collapse;

    // display: flex;
    flex-direction: column;
    overflow-x: auto;

    .row {
      display: table-row;
      border-bottom: 1px solid #e3e3e3;
      transition: background-color 0.2s ease;

      &:nth-child(odd) {
        background-color: #f2f2f77e; // color provided by Roland
      }

      &:hover {
        transition: background-color 0.2s ease;
        background: rgba(#29aae2, 0.15);
      }

      &:active {
        transition: background-color 0.1s ease;
        background: rgba(#29aae2, 0.25);
      }

      &.no-pointer-effects {
        pointer-events: none;
      }

      .col {
        display: table-cell;
        padding: 16px 1em;
        vertical-align: middle;

        & > span {
          display: -webkit-box;
          -webkit-line-clamp: 15;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .col-row-selector {
        width: 64px;
      }

      .col-actions {
        margin-left: auto;
        text-align: center;
        width: 64px;
        height: 64px;
      }
    }

    .row.table-header {
      // background: $grey700-4;
      // color: #fff;
      font-weight: $weight-bold;

      // New styles (which are actually old styles) as requested on 26.06.2019
      background-color: white;
      color: $grey900-5;
      border-bottom: 2px solid $grey700-4;
    }
  }

  .data-table .row.table-header .col {
    white-space: nowrap;
    padding-top: 1em;
    padding-bottom: 1em;
    user-select: none;

    &:not(.active) .sort-control-wrap:not(.active) {
      opacity: 0;
    }

    &:not(.active):hover .sort-control-wrap {
      opacity: 0.8;
    }

    .sort-control-wrap {
      display: inline-block;
      cursor: pointer;
      margin-left: 8px;

      position: relative;
      height: 18px;
      width: 14px;
      top: 3px;

      .sort-control {
        opacity: 0.5;
      }

      &:hover .sort-control {
        opacity: 0.3;
      }

      &:not(.active):hover .sort-control.sort-control-asc,
      &.active:hover.desc .sort-control.sort-control-asc,
      &.active:hover.asc .sort-control.sort-control-desc {
        opacity: 0.8;
      }

      &.active:not(:hover).asc .sort-control.sort-control-asc,
      &.active:not(:hover).desc .sort-control.sort-control-desc {
        opacity: 1;
      }

      .sort-control-asc {
        position: absolute;
        top: 0;
      }
      .sort-control-desc {
        position: absolute;
        top: 6px;
      }
    }
  }

  .table-footer {
    padding: 1em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 720px) {
      flex-flow: column-reverse nowrap;
      justify-content: center;
    }

    > * {
      margin: 0.5em 1em;
    }

    .pagination-selector {
      .label {
        vertical-align: middle;
        display: inline-block;
        margin: 0 1em 0 0;
      }

      .change-page-btn {
        vertical-align: middle;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25em;
        width: 2.5em;
        height: 2.5em;
        background: $grey200-1;
        border-radius: 50%;

        &:not(.disabled) {
          cursor: pointer;
          box-shadow: 0.25em 0.25em 0.5em 0 rgba(0, 0, 0, 0.15);
          &:hover {
            background: $grey400;
          }
        }
        &:not(:last-of-type) {
          margin-right: 0.5em;
        }
        &.disabled {
          color: $grey400;
        }
      }
    }
  }
}

.data-table-wrap.data-table-all-users {
  .col {
    &.col-row-selector {
      text-align: left;
      padding-right: 0;
      > * {
        vertical-align: middle;
      }
    }
    // &.col-id {
    //   width: 5em;
    //   overflow: hidden;
    //   white-space: nowrap;
    // }
    //     &.col-created {
    //       width: 8em;
    //     }
    //     &.col-updated {
    //       width: 8em;
    //     }
    //     &.col-actions {
    //       text-align: center;
    //       width: 4em;
    //       > * {
    //         text-align: left;
    //       }
    //     }
  }

  .col::before {
    display: block;
    content: '';
    height: 0;
    // height: 1px;
    // background: #f00;
  }
  .col-name::before {
    width: 9em;
  }
  .col-email::before {
    width: 15em;
  }
  .col-site::before {
    width: 10em;
  }
  .col-status::before {
    width: 5em;
  }
  .col-::before {
    width: em;
  }
  .col-::before {
    width: em;
  }
  .col-::before {
    width: em;
  }
  .col-::before {
    width: em;
  }
  .col-::before {
    width: em;
  }
  .col-::before {
    width: em;
  }
}

.actions-menu-container {
  > i {
    cursor: pointer;
  }

  .actions-menu {
    min-width: 150px;
    text-align: left;
  }

  .actions-menu .paper-content {
    color: $grey700-4;
    .item {
      cursor: pointer;
      white-space: nowrap;
      padding: 0.75em 1em;

      &:hover {
        background: $grey200-1;
      }
    }
  }
}

// /achieve/remediation/add
.data-table-add-remediation-task,
.data-table-edit-remediation-task {
  // background-color: pink;

  .row .col {
    width: 10em;
    &.col-number {
      width: 5em;
      // background-color: #123456;
    }
    &.col-task-name {
      width: 20em;
      // background-color: #ddd;
    }
    &.col-target-date {
      width: 10em;
    }
    &.col-assignee {
      width: 10em;
    }
    &.col-status {
      width: 10em;
    }
    &.col-actions {
      width: 6em;
      text-align: center;
      white-space: nowrap;
    }

    .fal.fa-trash-alt,
    .fal.fa-edit {
      padding: 10px;
      font-size: 2rem;
      color: $grey500-3;
      background-color: $grey200-1;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: white;
        background-color: $grey700-4;
        transition: all 0.2s;
      }
    }
  }
}

// /achieve/affiliates/edit
.data-table-edit-affiliate {
  .fal.fa-trash-alt,
  .fal.fa-edit {
    padding: 10px;
    font-size: 2rem;
    color: $grey500-3;
    background-color: $grey200-1;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: white;
      background-color: $grey700-4;
      transition: all 0.2s;
    }
  }
}

// /achieve/affiliates
.data-table-affiliates {
  col {
    text-align: left;
  }

  .fal.fa-ellipsis-h {
    height: 50px;
    width: 50px;
    font-size: 4rem;
    background: $grey300-2;
    color: $grey700-4;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.data-table-notify-affiliates {
  .col {
    height: 40px;
    vertical-align: middle;
  }

  .col-name {
    width: 10rem;
  }

  .col-requirement {
    width: 10rem;
  }

  .col-contact {
    width: 10rem;
  }

  .col-do-not-notify {
    width: 2rem;
    text-align: center;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.data-table-tasks {
  .col-1 {
    max-width: 450px;
  }
  .col-status > div {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
  .fal.fa-trash-alt,
  .fal.fa-edit {
    padding: 10px;
    font-size: 2rem;
    color: $grey500-3;
    background-color: $grey200-1;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: white;
      background-color: $grey700-4;
      transition: all 0.2s;
    }
  }
}

.custom-header-class {
  font-size: 14px;
  .col {
    & > div {
      display: inline-block;
      cursor: pointer;
    }
  }
}

.disabled-row {
  cursor: default !important;

  &:hover {
    background-color: #f3f3f3 !important;
  }
}

.clickable-row {
  cursor: pointer;
}

.data-table-audits {
  .clickable-row {
    cursor: pointer;
  }
  .remaining-questions {
    text-align: center;
  }
}

.col-active {
  width: 240px;
}

.remediation_title,
.remediation {
  width: 420px;
  min-width: 420px;
  max-width: 420px;
}

.data-table-documents {
  .table-data {
    .col-name {
      i {
        margin: 0 10px 0 0;
      }
    }
  }

  .actions {
    display: flex;
    text-align: center;
    justify-content: center;

    div {
      font-size: 1.5rem;
      font-weight: bold;
      height: 4rem;
      padding: 1rem 3rem;
    }
  }
}

.data-table-important-documents-dashboard,
.data-table-tasks-dashboard {
  margin: 0;
}

.data-table-document-history {
  .col-restore {
    .restore {
      text-decoration: underline;
      color: #03529c;
      cursor: pointer;
    }
  }
}

.fa-file-word {
  color: #2f5c98;
}

.fa-file-excel {
  color: #3a6e46;
}

.fa-file-pdf,
.fa-file-powerpoint {
  color: #c55736;
}

.fa-folder {
  color: #efcc69;
}

.fa-file-video {
  color: #fea82f;
}

.rows-per-page-selector {
  font-size: 14px;
  display: flex;
  align-items: center;

  &::after {
    margin-left: -20px;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font: var(--fa-font-solid);
    content: '\f0d7';
    color: #949494;
    // font: var(--fa-font-solid);
    // content: "\f1ea";
    pointer-events: none;
  }

  select {
    display: flex;
    padding-left: 8px;
    margin-left: 8px;
    max-width: 20px;
    appearance: none;
    font-size: 14px;
    background-color: white;
    outline: none;
    border-radius: 0;
    border: 2px solid #d3d3d3;
    cursor: pointer;

    &:focus {
      border-color: #838383;
    }
  }
}
