@import 'scss/variables.scss';

.overview {
  // height: 600px;
  background: $grey200-1;
  display: flex;
  flex-direction: column;
  // Replace bottom solution that removes white background between charts and tabs
  // margin-bottom: -20px;
  padding: 0 0;

  .heading {
    font-size: 2.8rem;
    font-weight: 500;
    margin: 10px 0 20px 0;
    // padding-top: 30px;
    // padding-bottom: 30px;
  }

  .stages {
    display: flex;
    width: 100%;
  }

  .charts {
    width: 100%;
  }

  .boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  @media (max-width: 992px) {
    .boxes {
      flex-direction: column;
      & > div {
        margin-bottom: 30px;
      }
    }
    .heading {
      font-size: 2rem;
      padding: 20px 0px 20px 0px;
    }
  }
}

.pageHeader {
  display: flex;

  .right {
    margin-left: auto;
    display: flex;

    & > div {
      margin-left: 10px;
      min-width: 150px;
    }

    .clear {
      width: 40px;
      margin-left: 10px;
    }
  }
}

.reset-filters {
  height: 40px;
  width: 40px;
  min-width: 40px;
  background: $grey300-2;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  margin-right: 0;

  &:hover {
    background: $grey500-3;
    color: white;
  }
}
