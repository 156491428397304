@import 'scss/variables.scss';

.radio {
  font-size: 1.6rem;
  color: $grey700-4;
  position: relative;
  cursor: pointer;
  height: 55px;
  display: flex;
  align-items: center;

  .hidden {
    display: none;
  }

  .radio-span {
    position: relative;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    min-width: 25px;
    border: 2px solid $grey200-1;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;

    &:after {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      width: 9px;
      height: 9px;
      border-radius: 100%;
      background: $grey700-4;
      transform: scale(0);
      // transition: all 0.12s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 0.1;
      pointer-events: none;
    }
  }
  &:hover {
    .radio-span:after {
      transform: scale(3.6);
    }
  }
  input[type='radio']:checked {
    visibility: hidden;

    + .radio-span {
      border-color: $grey200-1;
      &:after {
        transform: scale(1);
        // transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        opacity: 1;
      }
    }
  }
  input[type='radio']:disabled {
    + .radio-span {
      cursor: not-allowed;
      background-color: #f1f1f1;
    }
  }
}
