@import 'scss/variables.scss';

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
}

.holder {
  position: absolute;
  z-index: 9999;
  top: -75px;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (max-width: 840px) {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.modal {
  // min-width: 650px;
  max-width: 1140px;
  background: white;
  z-index: 200;
  position: relative;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.3);

  .close-modal {
    position: absolute;
    font-size: 4rem;
    color: $grey300-2;
    right: 30px;
    top: 25px;
    cursor: pointer;
    z-index: 9999;
  }
}
