$subtext-color: #636366;

.isLink {
  cursor: pointer;
}

.pie-chart-box {
  display: flex;
  width: 100%;
  background-color: white;
  margin-right: 30px;
  min-width: 200px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.1);
  user-select: none;

  .holder {
    display: flex;
    padding: 30px;
  }

  .text {
    max-width: 200px;
    color: $subtext-color;
    line-height: 2rem;
  }

  h2 {
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 5px 0;
  }

  .desc {
    min-height: 50px;
    font-size: 14px;
    line-height: 1.4;
    min-width: 300px;
  }

  .circle {
    max-width: 180px;
    width: 65%;

    .perc {
      font-size: 4rem;
      font-weight: 500;
      color: #21aae1;
    }
    .perc-text {
      font-size: 1.5rem;
      color: #777;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  .legend {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e3e3e3;
    padding-left: 30px;
    margin-left: 30px;
    width: 30%;
    font-size: 1.1rem;
    height: 100%;
    min-width: 180px;

    h1 {
      font-weight: 500;
      font-size: 32px;
      padding: 0;
      margin: 0;
    }

    .label {
      font-size: 1.3rem;
      color: $subtext-color;
    }

    .stats {
      position: relative;
      top: 30px;
      color: $subtext-color;
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 1650px) {
  .pie-chart-box {
    .desc {
      min-width: 220px;
    }

    .circle {
      min-width: 180px;

      .perc {
        font-size: 4rem;
        font-weight: 500;
        color: #21aae1;
      }
      .perc-text {
        font-size: 1.5rem;
        color: #777;
      }
    }
  }
}

@media (max-width: 1400px) {
  .pie-chart-box {
    .desc {
      min-width: 160px;

      h2 {
        font-weight: 500;
        margin: 0 0 5px 0;
      }
    }

    .circle {
      position: relative;
      top: 40px;
      min-width: 100px;

      .perc {
        font-size: 2.5rem;
        font-weight: 500;
        color: #21aae1;
      }
      .perc-text {
        font-size: 1rem;
        color: #777;
      }
    }

    .legend {
      padding-left: 10px;
      margin-left: 10px;
    }
  }
}

@media (max-width: 992px) {
  .pie-chart-box {
    .desc {
      min-height: 50px;
      line-height: 1.4;
      min-width: 300px;
    }

    .circle {
      position: relative;
      top: 0;
      max-width: 180px;
      width: 65%;

      .perc {
        font-size: 4rem;
        font-weight: 500;
        color: #21aae1;
      }
      .perc-text {
        font-size: 1.5rem;
        color: #777;
      }
    }
  }
}

@media (max-width: 990px) {
  .pie-chart-box {
    .desc {
      min-width: 160px;
    }

    .circle {
      position: relative;
      top: 0px;
      min-width: 100px;

      .perc {
        font-size: 3rem;
        font-weight: 500;
        color: #21aae1;
      }
      .perc-text {
        font-size: 1.5rem;
        color: #777;
      }
    }

    .legend {
      padding-left: 20px;
      margin-left: 20px;
    }
  }
}

@media (max-width: 630px) {
  .pie-chart-box {
    height: 230px;
  }
}

@media (max-width: 580px) {
  .pie-chart-box {
    .circle {
      .perc {
        font-size: 2rem;
        font-weight: 500;
        color: #21aae1;
      }
      .perc-text {
        font-size: 1.2rem;
        color: #777;
      }
    }
  }
}
