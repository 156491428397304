@import '../../scss/variables.scss';

.form-group {
  text-align: left;
  display: grid;
  grid-row-gap: 10px;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;

  label {
    @include input-label;

    @media (max-width: 992px) {
      letter-spacing: 0;
    }

    .small-text {
      margin-left: 6px;
      font-size: 1.4rem;
      color: $grey500-3;
      font-weight: 500;
    }
  }
  input {
    @include input;
    min-width: 0;
    text-indent: 1.2rem;
    background-color: white;

    &:focus,
    &:active {
      outline: none;
      border-color: #acacac;
    }

    &:disabled {
      background-color: #f1f1f1;
    }
  }

  textarea {
    border: 2px solid #e3e3e3;

    &:focus,
    &:active {
      outline: none;
      border-color: #acacac !important;
    }
  }

  .form-error-message {
    margin: 0;
    @include error-message;
  }

  .info-icon {
    position: absolute;
    bottom: 16px;
    right: -30px;
    color: $grey400;
    font-size: 2rem;
    cursor: pointer;
  }
}
