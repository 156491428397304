@import 'scss/variables.scss';

.modal-users {
  padding: 0px;
  min-width: max-content;
  min-height: max-content;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  input {
    margin-bottom: 10px;
  }

  .create {
    align-self: center;
    margin-left: 20px;
  }

  .modal-content {
    padding: 50px;
    height: 590px;
    overflow-y: scroll;
  }
}

.input {
  position: relative;
  padding: 0;
  color: #212121;
  font-size: 1.4rem;
  font-weight: 400;
  box-sizing: border-box;
  height: 55px;
  display: flex;
  align-items: center;
  border-radius: 0%;
  border: 2px solid #e0e0e0;
  min-width: 0;
  text-indent: 1.2rem;
  background-color: white;
  width: 100%;
  appearance: none;
  text-transform: none;
  margin-bottom: 10px;
}

.input_holder {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '▼';
    display: flex;
    position: absolute;
    transform: scaleY(0.5);
    right: 10px;
    top: 20px;
    color: #9e9e9e;
  }
}

.drop {
  font-size: 12px;
  text-transform: uppercase;
  color: #444;

  select {
    padding: 6px;
    border: 2px solid #e3e3e3;
  }
}

.user-email-addresses-users-modal {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;

  .email-addresses {
    margin-top: 10px;
    flex-basis: 100%;
    padding: 20px;
    resize: none;
    border: 0.14em solid $grey300-2;
    color: $grey900-5;
    font-size: 1.6rem;
  }

  // Italic placeholder
  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }
}

.add-user-group {
  font-size: 22px;
  cursor: pointer;
}

.divider {
  height: 30px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
}

.delete-user-group {
  font-size: 22px;
  cursor: pointer;
  float: left;
}

.checkbox {
  float: left;
  margin-bottom: 10px;
}

.clear {
  clear: both;
}
