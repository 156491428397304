@import 'scss/variables.scss';

.add-new-incident-form {
  margin: 20px 0;
  .add-new-incident-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 25px;
    align-items: flex-start;

    @media (max-width: 840px) {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    .investigation-status {
      align-self: flex-start;
    }

    .date-inputs-row {
      .date-input {
        width: 100%;
        background-color: white;
      }
    }

    .affected {
      display: flex;
    }

    .last_note {
      grid-column: span 2;

      textarea {
        padding: 10px;
        height: 160px;
        resize: vertical;
        margin-bottom: 20px;
      }
    }
  }

  .tabs {
    background-color: $grey200-1;
    height: 200px;
    margin: 100px 0;
    text-align: right;

    .tabs-container {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      grid-gap: 25px;
    }
  }

  .hr {
    margin: 50px auto;
  }

  .incident-description {
    width: 100%;
    background-color: white;
    border: 2px solid $grey300-2;
    padding: 25px;
    font-size: 1.8rem;
    color: $grey900-5;
    resize: none;
  }

  .form-actions {
    display: grid;
    grid-template-columns: max-content auto max-content max-content;
    grid-gap: 25px;

    .cancel-button {
      grid-column: -2/-3;
    }

    .update-incident-button {
      grid-column: -1/-2;
    }
  }
}

.marginLeft {
  margin-left: 20px;
}
