@import 'scss/variables.scss';

.title {
  display: flex;
  margin: 20px 0;
  h3 {
    font-weight: 500;
    font-size: 2.5rem;
    margin: 0;
  }
}

.formWrapper {
  container-type: inline-size;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 25px;
  margin: 0 auto 20px;

  @container (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }

  // So an error in one formElement doesn't misplace the contents of another
  > * {
    align-content: flex-start;
  }

  .contact-email {
    grid-column: 1/2;
  }

  .contact-phone-input {
    width: 100%;
  }

  .upload-documents {
    grid-column: 1/-1;
  }

  .notes {
    resize: none;
    height: 100px;
    padding: 10px;
    // As is in the design, I prefer it without
    // width: calc(100% + 100px);
  }

  .hr {
    grid-column: 1/-1;
    color: $grey100;
    margin-top: 50px;
  }

  .form-options {
    grid-column: 1/-1;
    justify-content: flex-end;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: max-content max-content;
  }

  // div.button {
  //     height: 5.5rem;
  // }
}
