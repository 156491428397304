.main-layout {
    body {
        overflow-y: hidden;
    }

    .main-header {
        height: 60px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        width: 100%;
        border-bottom: 2px solid #eeeeee;

        .main-header-left {
            display: flex;
            align-items: center;
            gap: 10px;

            .company {
                font-size: 16px;
                font-weight: 500;
                color: #666666;
            }

            .drawer-button {
                border: none;
                background-color: transparent;
                font-size: 23px;
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                    height: 29px;
                    width: 29px;
                }
            }
        }

        .main-header-right {
            display: flex;
            align-items: center;

            button.report {
                padding: 0;
            }
            
            a {
                text-decoration: none;
                color: #21a9e1;
                text-transform: none;
                font-size: 16px;
                font-weight: 400;
                display: flex;
                align-items: center;
                height: 100%;
                padding: 3px 8px;

                svg {
                    margin-top: 5px;
                    height: 22px;
                    width: 22px;
                }

                .label {
                    margin-left: 5px;
                }
            }
        }
    }
    
    .main-content {
        height: calc(100vh - 60px);
        overflow-x: auto;
        overflow-y: auto;
    
        h1 {
            margin: 0;
        }
    }
}
