.org-info-holder {
  display: flex;
  justify-content: space-around;

  .org-sites,
  .org-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    border: 1px solid #e3e3e3;
    padding: 30px;
    margin-right: 30px;

    & > div {
      margin-bottom: 20px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .org-sites {
    width: 100%;

    ul {
      margin: 0;
      padding: 0;
    }
    li {
      text-transform: uppercase;
      list-style: none;
      padding: 20px 0;
      border-bottom: 1px solid #e3e3e3;

      &.site {
        padding-left: 30px;
      }
      &.dept {
        padding-left: 60px !important;
      }
    }
  }
  .org-info {
    width: 80%;
  }

  @media (max-width: 1120px) {
    flex-direction: column;

    .org-info {
      margin-top: 30px;
      width: 100%;
    }
  }
}

li.org-site {
  user-select: none;
  list-style: none;
  padding: 20px 0;
  border-bottom: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
  margin-top: -1px;
  display: flex;
  background-color: white;
  text-transform: uppercase;
  overflow: hidden;

  & > span i {
    margin-right: 15px;
    margin-left: 15px;
    cursor: pointer;
  }

  form {
    display: block;
  }

  .item-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value {
    margin-left: 4px;
  }

  input {
    padding: 10px;
    margin: 0 10px;
  }

  & > span:last-of-type {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  &.site {
    padding-left: 30px;
  }
  &.dept {
    background-color: white;
    // cursor: grab;
    text-transform: uppercase;
    padding-left: 60px !important;
  }
}

.org-site-bottom-buttons {
  display: flex;
  justify-content: space-around;
}

.organizations-wrap {
  margin: 0 50px;
}

.input {
  position: relative;
  padding: 0;
  color: #212121;
  font-size: 1.4rem;
  font-weight: 400;
  box-sizing: border-box;
  height: 55px;
  display: flex;
  align-items: center;
  border-radius: 0%;
  border: 2px solid #e0e0e0;
  min-width: 0;
  text-indent: 1.2rem;
  background-color: white;
  width: 100%;
  appearance: none;
  text-transform: none;
}

.input_holder {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '▼';
    display: flex;
    position: absolute;
    transform: scaleY(0.5);
    right: 10px;
    top: 20px;
    color: #9e9e9e;
  }
}

.checkbox {
  display: flex;
  padding-left: 0px !important;
}

.form-filled {
  color: #34c759;
}

.error-message {
  color: #e2234b;
  font-size: 1.4rem;
  text-transform: lowercase;
}

.error-message::first-letter {
  text-transform: uppercase;
}

.icon-editing-adjustment {
  position: relative;
  top: 12px;
}
