@import 'scss/variables.scss';

.editor {
  min-height: 100vh;
  min-width: 80%;
  margin: 0 auto;
  display: flex;
  background-color: red;
}

.up {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em 0.6em;
  background: $grey200-1;
}

.path {
  height: 48px;
  margin: 0 60px;
  display: flex;
  align-items: center;
}

.holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  width: 120px;
  margin-right: auto;
  margin-bottom: 20px;
}

.video {
  width: 80%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
