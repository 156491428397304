@import 'scss/variables.scss';

.modal {
  padding: 0px;
  min-width: max-content;
  min-height: max-content;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .create {
    align-self: center;
    margin-left: 20px;
  }

  .modal-content {
    padding: 50px;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }

  .siteAll {
    display: flex;
    width: 100%;
  }
}

.holder {
  display: flex;
  align-items: flex-start;
}

.launch-date {
  margin-left: 20px;
}

.complete {
  display: flex;
  margin-left: -5px;
}
