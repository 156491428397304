@import 'scss/variables.scss';

div.modal {
  height: auto;
  margin: 100px auto;
  // margin: 100px auto 0 auto;
  .modal-header,
  .modal-footer {
    background-color: $grey200-1;
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }

  .modal-body {
    color: $grey500-3;
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    .flex-container {
      display: flex;
      flex-direction: column;
      width: 90%;
    }

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 0 0 100%;
      text-align: left;
      padding: 5px 0;
    }

    .label {
      width: 120px;
      font-weight: bold;
      color: #000000;
    }

    .text {
      flex-grow: 1;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-basis: 100%;
    }

    .table {
      margin: 50px 0 0 0;
      width: 100%;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
}
