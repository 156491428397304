.mfa {
  padding-top: 20px;

  .mfa-title {
    font-size: 2rem;
    margin: 40px auto 60px auto;
  }

  .mfa-sub-title {
    width: 400px;
    font-weight: 400;
    margin: 0 auto;
  }

  .code-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;

    .input-box {
      width: 3.5rem;

      input {
        font-size: 3.3rem;
        line-height: 2.25rem;
        font-weight: 400;
        width: 100%;
        text-align: center;
        border: 1px solid #9f9f9f;
        border-radius: 5px;
        padding: 5px 0;
        text-indent: 0;
      }

      input:focus {
        border: 2px solid #0a407e;
        box-shadow: 0px 0px 0px 7px rgba(33, 169, 225, 0.2);
        caret-color: transparent;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  p {
    margin: 10px auto !important;
  }

  a {
    text-decoration: none;
    color: black;
    position: relative;
    display: inline;
    margin-bottom: 2px;
    block-size: fit-content;
    width: fit-content;
    color: #21a9e1;
  }

  a:hover {
    color: #21a9e1;
  }

  a::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #21a9e1;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  a:hover::before {
    transform: scaleX(1);
  }

  .countdown {
    color: #e2234b;
    margin-bottom: 10px;
  }
}
