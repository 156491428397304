@import 'scss/variables.scss';

div.modal {
  height: initial;
  // margin: 100px auto 0 auto;
  .modal-header,
  .modal-footer {
    background-color: $grey200-1;
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }

  .modal-body {
    // color: $grey500-3;
    text-align: center;
    margin: 30px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    .validation-error {
      align-self: center;
      font-size: 14px;
      color: #e2234b;
      margin-top: 20px;
    }

    b,
    strong {
      color: $grey500-3;
      font-weight: 500;
    }

    .existing-folder {
      display: grid;
      grid-template-columns: 1fr 2fr;
      width: 80%;
      grid-gap: 20px;
      align-items: flex-start;

      label {
        text-align: left;
        // height: 75px;
        color: $grey700-4;
      }

      .folder-name {
        padding: 1rem;
      }

      .parent-folder {
        list-style: none;
        display: grid;
        text-align: left;
        height: 100%;
        padding: 0px;
        grid-gap: 5px;

        &:hover {
          background-color: white;
        }

        li.folder {
          display: grid;
          grid-template-columns: max-content 1fr;
          grid-column-gap: 10px;
          color: $grey700-4;
          align-items: center;
          padding-top: 8px;
          padding-bottom: 8px;

          i {
            font-size: 2.4rem;
          }

          &:hover {
            background-color: #e8f6fc;
          }
        }
        @for $i from 0 through 10 {
          .depth-#{$i} {
            padding-left: #{(($i) * 30 + 8)}px;
          }
        }
      }
    }

    .button {
      width: max-content;
    }

    .download-button {
      @include btn-lg-primary-outline;
      border-radius: 5rem;
      text-decoration: none;
    }
  }

  .permissions {
    text-align: left;
    padding: 75px 0 0 0;
  }

  .permission-level {
    display: flex;
    margin: 10px 0 0 0;
  }

  .permission-department {
    padding: 17px;
    margin: 0 1px 0 0;
  }

  .expiration-date {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-top: 20px;
    width: 80%;
    grid-gap: 20px;
  }

  .expiration-date-label {
    text-align: left;
    padding: 20px 0;
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 50px;
  }

  .date-input {
    width: 100%;
    background-color: white;
  }
}

.reg {
  display: flex;
  // margin: auto 0;
  padding: 20px 0;
}

.display-none {
  display: none;
}

.display-inline {
  display: inline;
  position: relative;
}

.holder {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > div:last-of-type {
    margin-left: 10px;
  }
}

.training {
  display: flex;
}
