.stepper {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  flex: auto;

  .stepper_holder {
    display: flex;
    background-color: white;
    // height: 120px;
    // align-items: center;
    // justify-content: center;
    // max-width: 1280px;
    width: 100%;
    margin: 0 auto;

    & > div {
      display: flex;
      background-color: white;
      height: 120px;
      align-items: center;
      justify-content: center;
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  .stepper_content {
    display: flex;
    position: relative;
    width: 500px;
    justify-content: space-between;
    margin: 0 auto;

    .line {
      position: absolute;
      top: 0;
      width: 100%;

      .after {
        top: 12px;
        position: absolute;
        width: 50%;
        border-top: 3px solid #b4b4b4;
        z-index: 56;
        // margin: 0 60px;
        right: 0;
      }
      .before {
        top: 12px;
        position: absolute;
        width: 100%;
        border-top: 3px solid #21aae1;
        z-index: 55;
        // margin: 0 45px;
      }
    }

    // &::before {
    //     top: 12px;
    //     position: absolute;
    //     content: '';
    //     width: 40%;
    //     border-top: 3px solid #B4B4B4;
    //     z-index: 56;
    //     margin: 0 60px;
    //     right: 0;
    // }

    // &::after {
    //     top: 12px;
    //     position: absolute;
    //     content: '';
    //     width: 80%;
    //     border-top: 3px solid #21AAE1;
    //     z-index: 55;
    //     margin: 0 45px;
    // }

    & > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 110px;
      font-size: 15px;
      font-weight: 500;
      // margin-right: 20px;
    }

    .circle {
      display: flex;
      height: 25px;
      width: 25px;
      border-radius: 25px;
      z-index: 99;
      margin-bottom: 20px;
    }

    .active {
      background-color: white;
      border: 3px solid #21aae1;
    }

    .complete {
      background-color: #21aae1;
      border: 3px solid #21aae1;
    }

    .disabled {
      background-color: #b4b4b4;
      border: 3px solid #b4b4b4;
    }
  }

  .step {
    &:first-of-type {
      align-items: flex-start;
    }
    &:last-of-type {
      align-items: flex-end;
    }
  }

  .step_title {
    top: 38px;
    position: absolute;

    margin-left: -40px;

    &:last-of-type {
      margin-right: -32px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 250px;
  bottom: 0;
  margin-bottom: 10px;
}

.header {
  text-align: center;
  justify-content: center;
  background-color: #eee;
  width: 100%;
  height: 120px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
}

.footer {
  text-align: center;
  justify-content: center;
  background-color: #eee;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  & > div {
    display: flex;
    margin: auto;
    max-width: 960px;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1000px) {
      min-width: auto;
      max-width: 760px;
    }
    @media (max-width: 800px) {
      min-width: auto;
      max-width: 640px;
    }
  }
}

.attestButton {
  margin-top: 40px;
}

.leftButton {
  margin-right: 50px;
}

.rightButton {
  margin-left: 50px;
}
