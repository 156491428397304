@import 'scss/variables.scss';

.tab {
  display: flex;
  background-color: white;
  padding: 20px;
  position: relative;
  max-width: 350px;
  height: 60px;
  min-width: 250px;
  cursor: pointer;
  // transition: align-self 0.1s;
  transition: box-shadow 0.1s;

  // &:hover:not(.active) {
  //     @include boxshadow(1);
  //     // transition: align-self 0.1s;
  //     transition: box-shadow 0.1s;
  // }

  &.active {
    align-self: flex-end;
    position: relative;
    min-height: 70px;
    bottom: 0;

    .tab-icon {
      background-color: $light-blue;
      // background-color: $blue1-byRoland;
    }
  }
  .tab-icon {
    font-size: 2rem;
    color: white;
    padding: 25px;
    border-radius: 2px;
    // background-color: $blue2;
    background-color: $blue1-byRoland;

    position: relative;
    top: -30px;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    @include boxshadow(2);

    @media (max-width: 992px) {
      display: none !important;
    }
  }

  .tab-info {
    display: grid;
    justify-items: flex-end;
    grid-template-rows: max-content max-content;
    grid-gap: 25px;

    @media (max-width: 992px) {
      justify-items: flex-start;
    }

    .tab-heading {
      color: $grey900-5;
      font-size: 1.6rem;
      font-weight: 500;
      margin-left: 15px;
    }

    .tab-description {
      color: $grey500-3;
      font-size: 2rem;
      font-weight: 300;
    }
  }
}
