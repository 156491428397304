@import 'scss/variables.scss';

div.modal {
  height: initial;
  .modal-header,
  .modal-footer {
    background-color: $grey200-1;
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }

  .modal-body {
    color: $grey500-3;
    text-align: center;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button {
      width: max-content;
    }

    .download-button {
      @include btn-lg-primary-outline;
      border-radius: 5rem;
      text-decoration: none;
    }

    .drag-n-drop {
      .drag-icon {
        font-size: 5rem;
      }

      .line {
        display: inline-block;
        width: 25px;
        margin: 0;
        vertical-align: middle;
        border: 1px solid $grey500-3;
      }
      margin: 50px auto;
      padding: 40px;
      width: 80%;
      display: grid;
      align-items: center;
      justify-content: center;
      grid-gap: 20px;
      background-color: $grey100;
      border: 2px dashed $grey300-2;

      .browse {
        position: relative;
        input[type='file'] {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 70px;
          left: 0px;
          cursor: pointer;
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
}

.site_selector {
  width: 80%;
  margin-bottom: 30px;
  color: black;
}
