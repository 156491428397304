.item {
  display: flex;
  padding: 5px 15px 5px 0px;
  cursor: pointer;

  label {
    width: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
  }

  &:hover {
    background-color: #f3f3f3;
  }
}
