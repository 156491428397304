.org_header {
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d1d6;
}

strong {
  font-weight: 500;
}

.top {
  display: flex;
  align-items: center;

  .right {
    display: flex;
    margin-left: auto;
  }
}

.bottom {
  margin-top: 10px;
  display: flex;
}

.org_name {
  font-size: 32px;
  font-weight: 500;
}

.settings {
  align-items: flex-end;
  display: flex;
  margin-left: auto;
}
