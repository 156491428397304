@import 'scss/variables.scss';

.add-user-form {
  // background: blue;
  @media (max-width: 768px) {
    margin: 20px;
  }

  .holder {
    display: flex;
    flex-direction: row;
    width: 100%;
    // background-color: red;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 0;

      .left,
      .right {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        margin: 0 !important;
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .right {
      margin-left: 40px;
    }

    .form_element {
      margin-bottom: 25px;
    }
  }

  .submit-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > div {
      margin-left: 20px;
    }
  }

  // .status-row {
  //     align-items: center;
  //     grid-gap: 50px;
  // }

  .contact-phone-input {
    width: 100%;
  }

  .form_element_contact_phone {
    margin-top: -10px;
  }

  // .access-permissions {
  //     grid-column: 1/2;
  // }

  // .step-one-box {
  //     grid-row: 2/3;
  //     grid-column: 1/2;
  // }

  // .step-two-box {
  //     grid-row: 2/3;
  //     grid-column: 2/3;
  // }

  // .step-three-box {
  //     grid-column: 2/3;
  // }

  .hr {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  // .submit-box {
  //     grid-column: 2/3;
  //     display: grid;
  //     grid-template-columns: repeat(2, max-content);
  //     justify-content: flex-end;
  //     grid-gap: 20px;
  // }

  .password {
    padding-right: 43px;
  }

  .show-password {
    .password-button {
      position: absolute;
      right: 2px;
      top: 42px;
      width: 42px;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;

      i {
        font-size: 22px;
        color: #bbbbbb;
      }
    }
  }
}

.loader {
  position: relative;
  div {
    position: absolute;
    z-index: 1;
  }
}
