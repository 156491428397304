@import 'scss/variables.scss';

.checkbox-container {
  // height: 48px;
  // width: 48px;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 0.5em;
  padding-left: 5px !important;
  position: relative;

  .input-checkbox {
    display: none;
  }

  .input-checkbox + i {
    font-size: 1.5em;
    border: 0.12em solid $grey300-2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.4em;
    width: 1.4em;
    background-color: white;
    margin-right: 0px;
    min-width: 30px;
    cursor: pointer;

    &:active {
      background-color: rgb(238, 238, 238);
      border: 0.12em solid rgb(207, 207, 207);
    }
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .input-checkbox:disabled + i {
    background-color: rgb(238, 238, 238);
  }

  .input-checkbox:checked + i:after {
    content: '';
    display: block;
    width: 0.3em;
    height: 0.5em;
    border: solid $grey900-5;
    border-width: 0 0.11em 0.11em 0;
    transform: rotate(45deg);
    position: absolute;
    // top: -0.1em;
  }

  .input-checkbox-partial:checked + i:after {
    content: '';
    display: block;
    width: 0;
    height: 0.5em;
    border: solid $grey900-5;
    border-width: 0 0.11em 0 0;
    transform: rotate(90deg);
    position: absolute;
    // top: -0.1em;
  }
}

$width: 55px;
$padding: 7px;
$height: calc($width / 2);

.switch-wrap {
  background-color: $green1;
  border-radius: calc($height / 2);
  height: $height;
  width: $width;
  position: relative;
  cursor: pointer;

  &.switch-wrap-inactive {
    background-color: $grey300-2;

    .switch::after {
      border: 2px solid $grey300-2;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    height: $height;
    width: $width;
    position: absolute;
    cursor: pointer;
  }

  .switch {
    height: 100%;
    display: grid;
    grid-template-columns: 0fr 1fr 1fr;
    transition: 0.2s;
    //ICYMI, pseudo elements are treated as grid items
    &::after {
      content: '';
      border-radius: 50%;
      background: white;
      grid-column: 2;
      border: 2px solid $green1;
      transition: background 0.2s;
    }
  }
  input:checked {
    + .switch {
      grid-template-columns: 1fr 1fr 0fr;
      &::after {
        background-color: white;
      }
    }
  }
}
