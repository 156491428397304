@import 'scss/variables.scss';

.form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 15px 0;
}

.form_confirm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 22px 0 23px;
}

.form_confirm_value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 140px;
  width: 33%;
}

.link {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 26px 0 31px;
  cursor: pointer;
  color: $blue1-byRoland;

  &:hover {
    color: $blue1-dark;
    text-decoration: underline;
  }
}

.icon {
  margin-right: 16px;
  font-size: 2.4rem;
  color: $grey300-2;
}

.form_element {
  width: 350px;
  margin-right: 8px;
}

.status {
  margin-left: 8px;
  margin-right: 8px;
}

.button_group {
  width: 33%;
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  div {
    margin-left: 8px;
  }
}

.title {
  min-width: 100px;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.verified {
  display: flex;
  justify-content: center;
  width: 33%;
  min-width: 90px;
  color: $blue1-dark;
}

.address {
  margin-left: 40px;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
}

.resend,
.cancel {
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    color: $blue1-dark;
    text-decoration: underline;
  }
}

// overwrite phone input styles

.PhoneInput {
  border: 2px solid #e0e0e0;
  padding: 0px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline !important;

  input {
    height: 51px;
    border: none;
  }
}
