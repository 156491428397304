.modal {
  min-width: max-content;
}

.create {
  align-self: center;
  margin-left: 20px;
}

.text_left {
  text-align: left;
}

.checkbox {
  display: flex;
  padding-left: 0px !important;
}
