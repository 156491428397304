@import 'scss/variables.scss';

.tab-tasks {
  margin-bottom: 50px;

  .header {
    display: flex;
    // grid-template-columns: max-content max-content auto max-content;
    align-items: center;
    margin: 20px auto;

    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 21px;
      text-transform: capitalize;

      @media (max-width: 992px) {
        font-size: 2.1rem;
      }

      .header-slash {
        color: $grey500-3;
        font-weight: 300;
      }
    }

    i {
      color: $grey500-3;
    }

    .button {
      margin-left: auto;
      font-size: 1.4rem;

      @media (max-width: 992px) {
        font-size: 1.2rem;
        line-height: 38px;
        height: 40px;
        padding-left: 25px;
        padding-right: 20px;
      }

      .button-arrow {
        font-size: 1.4rem;
        color: white;
        margin-left: 20px;
      }
    }
  }
}

.pageHeader {
  background-color: white;
  padding: 0 0 20px 0 !important;
}

.page-header-actions-dashboard {
  flex-direction: row;
}
