.dimmer-hidden {
  display: none;
}

.dimmer {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  i {
    font-size: 50px;
    color: black;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
