@import 'scss/variables.scss';

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
}

.holder {
  position: absolute;
  z-index: 9999;
  //top: -148px;
  top: 0;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  margin-top: 60px;
  padding-bottom: 10px;

  @media (max-width: 840px) {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.modal {
  // min-width: 650px;
  max-width: 1140px;
  background: white;
  z-index: 200;
  position: relative;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.3);

  .close-modal {
    position: absolute;
    font-size: 4rem;
    color: $grey300-2;
    right: 30px;
    top: 25px;
    cursor: pointer;
    z-index: 9999;
  }
}

.modal {
  // padding: 0px;
  // min-width: max-content;
  // min-height: max-content;
  // height: auto !important;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  text-align: center;

  // .create {
  //     align-self: center;
  //     margin-left: 20px;
  // }

  // .siteAll {
  //     display: flex;
  //     width: 100%;
  // }
}

.modal-heading {
  flex-direction: row;
  background-color: $grey100;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
  grid-column: 1/-1;
  margin: 0;
}

.modal-content {
  padding: 50px;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  background-color: $grey100;
  height: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;

  .heading {
    font-size: 3.6rem;
    font-weight: 500;
    margin: 0;
  }

  .description {
    color: $grey500-3;
    font-weight: 500;
  }
}
