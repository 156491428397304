.tasks_page_header {
  @media (min-width: 1500px) and (max-width: 1880px) {
    display: flex;
    flex-direction: column-reverse;

    & > div:first-child {
      margin-top: 20px;
    }
  }
}
