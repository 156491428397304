@import 'scss/variables.scss';

.path-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.up {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  box-shadow: 0px 0px 2px #888;
  width: 40px;
  height: 35px;
  background: $grey200-1;
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.path {
  height: 48px;
  margin: 0 60px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
