@import 'scss/variables.scss';

.up {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em 0.6em;
  background: $grey200-1;
}

.link {
  cursor: pointer;
}

.path {
  height: 48px;
  margin: 0 60px;
}
