@import 'scss/variables.scss';

.editor {
  min-height: 100vh;
  min-width: 80%;
  margin: 0 auto;
  display: flex;
  background-color: red;
}

.up {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em 0.6em;
  background: $grey200-1;
}

.path {
  height: 48px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveas {
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  padding: 0 50px;
  align-items: center;
  height: 80px;
}
