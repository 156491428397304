@import 'scss/variables.scss';

.header {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  position: relative;
  z-index: 99;
  user-select: none;

  & > div {
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 100px;
    align-items: center;
  }

  .logo {
    display: flex;
    flex-direction: column;
    margin: 0 10px 0 30px;
    width: 283px;
    min-width: 283px;
    cursor: pointer;
  }

  .menu {
    display: flex;
    width: 100%;
    // padding: 0 10px;
    background-color: white;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    @media (max-width: 920px) {
      display: none;
    }

    @media (max-width: 1100px) {
      justify-content: space-evenly;
    }

    & > div {
      display: flex;
      margin: 0 10px;
      align-items: center;
      padding: 10px 20px;
      cursor: pointer;
      letter-spacing: 0.5px;

      @media (max-width: 1400px) {
        padding: 5px 10px;
      }

      @media (max-width: 1300px) {
        padding: 5px 10px;
        margin: 0;
        // transform: scale(0.85);
      }

      @media (max-width: 1200px) {
        padding: 0;
        margin: 0;
        transform: scale(0.85);
      }
      @media (max-width: 1100px) {
        flex-direction: column;
        text-align: center;
        margin: 2px;

        .icon {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }

    .icon {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      background-color: #044f9c;
      // background-color: #FF9000;
      border-radius: 48px;
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .active {
    color: $light-blue;
    & > i {
      background-color: $light-blue !important;
    }
  }

  .panel {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: white;
    width: 250px;
    min-width: 250px;
    margin-left: 20px;
    // padding: 0 10px;
    margin-right: 10px;

    @media (max-width: 720px) {
      width: 100px;
      min-width: 100px;
    }
  }

  .panel_hamburger {
    display: flex;
    width: 64px;

    @media (min-width: 920px) {
      display: none;
    }
  }

  .submenu {
    display: flex;
    background-color: #fff;
    border-top: 1px solid #e3e3e356;
    height: 48px;
    align-items: center;
    justify-content: center;

    @media (max-width: 920px) {
      display: none;
    }

    .subItem {
      font-size: 14px;
      margin: 0;
      letter-spacing: 0.5px;
      // text-transform: uppercase;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 30px;
      cursor: pointer;

      &:hover {
        background-color: #f1f1f1;
      }
    }
    .subactive {
      background-color: #f1f1f1;
    }
  }
}
