.name, .description {
  font-size: 1.1em;
  min-width: 200px;
  max-width: 280px;
}

.description {
  font-size: 0.95em;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}