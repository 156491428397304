@import 'scss/variables.scss';

.main-menu {
  font-weight: 400;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  a {
    color: #21a9e1;
    text-decoration: none;
    position: relative;
    display: inline;
    block-size: fit-content;
    width: fit-content;
  }

  .spacer {
    margin: 20px auto;
    width: 80%;
    border: 1px solid #eeeeee;
  }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      position: sticky;
      background-color: white;
      top: 0;
      z-index: 9;
      margin-bottom: 40px;

      .logo {
        width: 220px;
      }

      .logo-small {
        width: 50px;
      }
    }
  
    .menu-items-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;      
  
      .menu-item-wrapper {
        height: auto;
        color: $light-blue;
        font-size: 16px;
        width: 260px;

        .menu-icon {
          margin-right: 10px;
          text-align: center;
          margin-top: 4px;

          svg {
            height: 22px;
            width: 22px;
          }
        }

        .menu-label {
          margin-right: 10px;
        }

        .menu-item-label {
          width: 100%;
          opacity: 1;
          transition: opacity 0.2s ease;

          &.hide {
            opacity: 0;
            transition: opacity 0.2s ease;
          }
        }

        .menu-item-accordion {
          padding: 7px 20px;
        }

        &.menu-item-link {
          padding: 0 20px;
          height: 42px;
          display: flex;
          align-items: center;
          transition: all .5s ease;

          &:hover {
            background-color: #d4eef9;
            color: #666666;
            transition: all .5s ease;
          }
        }
        
        .menu-subitem-wrapper {
          &:hover {
            background-color: #d4eef9;
          }
          .menu-subitem {
            display: block;
            width: 100%;
            padding-left: 50px;
            display: flex;
            align-items: center;
            height: 42px;
          }
        }        
    
        .submenu-activate {
          // padding: 7px 0;
          i {
            margin-right: 10px;
          }

          &:hover {
            background-color: #d4eef9;
            width: 100%;
            cursor: pointer;
          }

          &.submenu-active {
            &:hover {
              background-color: #ffffff;
            }
          }
        }
      }
    }
  
    .hipaa-seal-logo {
      display: flex;
      justify-content: center;
      margin-top: auto;
    
      img {
        width: 100px;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      &.collapsed-menu {
        img {
          display: none;
          // width: 50px;
          // margin-top: 40px;
          // margin-bottom: 40px;
        }
      }
    }
}
