.nav-item.main {
  .nav-link {
    display: inline-block;
    text-decoration: none;
    color: $blue1-light;
    text-align: center;

    i.icon {
      margin: 0.25em;
      background: $blue1-light;
      color: #fff;
      border-radius: 50%;
      padding: 0.5em;
      width: 2em;
      height: 2em;
      display: inline-flex;
      font-size: 1.6em;
      vertical-align: middle;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      &::before {
        display: block;
      }

      &.fa-tachometer::before {
        position: relative;
        top: -0.08em;
      }

      @media (max-width: $header-breakpoint-1) {
        font-size: 1.2em;
      }
    }
    span.label {
      font-weight: $weight-bold;
      display: inline-block;
      margin-left: 0.5em;
      vertical-align: middle;

      @media (max-width: $header-breakpoint-1) {
        font-size: 0.85em;
      }
    }

    @media (max-width: $header-breakpoint-3) {
      i.icon {
        display: flex;
        margin: 0.25em auto 0.15em auto;
      }
      span.label {
        margin-left: 0;
        margin-bottom: 0.3em;
      }
    }
  }
  &.active {
    .nav-link {
      i.icon {
        background: $light-blue;
      }

      span.label {
        color: $light-blue;
      }
    }
  }
  &.disabled {
    opacity: 0.25;
    cursor: default;
  }
}

.logo {
  min-width: 200px;
  max-width: 320px;
  flex: 1 1 25%;
  border-right: 1px solid #000;

  @media (max-width: $header-breakpoint-2) {
    min-width: 160px;
    flex-basis: 20%;
  }

  a {
    width: 100%;
    height: 100%;
    padding: 0.5em 1em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    @media (max-width: $header-breakpoint-1) {
      padding: 0.5em 0.5em;
    }

    .logo-split {
      display: none;

      img {
        display: block;
        width: 100%;
        margin: 0 auto;

        &.logo-split-graphic {
          width: 3em;
          margin-bottom: 0.3em;
        }
      }
    }

    .logo-single {
      display: block;

      img {
        display: block;
        width: 100%;
      }
    }

    @media (max-width: $header-breakpoint-2) {
      .logo-split {
        display: block;
      }

      .logo-single {
        display: none;
      }
    }
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  background: #fff;
  // border-bottom: 1px solid #000;

  .nav-panel {
    flex: 1 1 85%;

    .top-nav-panel {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: stretch;

      .main-nav {
        flex: 1 1 85%;

        ul {
          @include unstyled-list;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-evenly;
          justify-content: space-around;
          align-items: flex-start;
        }

        @supports (-ms-ime-align: auto) {
          /* Edge only */

          ul {
            justify-content: space-around;
            /* you can also add some other adjustments to size, margins etc to get the same positioning */
          }
        }
      }

      .user-nav {
        display: flex;
        min-width: 220px;
        max-width: 220px;
        position: relative;

        .user-info {
          width: 180px;
          @media (max-width: $header-breakpoint-1) {
            font-size: 0.85em;
          }
          .user-name,
          .user-company {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 160px;
          }
          .user-name {
            font-weight: $weight-bold;
          }

          .user-company {
            font-size: 0.85em;
          }
        }

        .user-panel {
          // background: $blue1-light;
          background-color: white;
          color: black;
          height: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;
          align-items: center;
          padding: 0 0.5em;
          transition: background-color 0.2s, color 0.2s;

          &:hover {
            cursor: pointer;
            color: black;
            background-color: white;
            // background: $blue1-dark;
          }

          .user-thumbnail {
            background: $blue1-dark;
            flex: 0 0 3em;
            width: 3em;
            height: 3em;
            border-radius: 50%;
            box-shadow: 0.15em 0.15em 0.5em 0 rgba(0, 0, 0, 0.25);
            border: 2px solid rgba(255, 255, 255, 0.15);
            overflow: hidden;

            @media (max-width: $header-breakpoint-1) {
              flex-basis: 2.5em;
              width: 2.5em;
              height: 2.5em;
            }

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            i {
              font-size: 1.5em;
              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
            }
          }

          .user-info {
            flex: 1 2;
            min-width: 0;
            margin: 0 0.5em;
          }

          .user-menu-arrow {
            flex: 0 0 0.5em;

            i {
              display: block;
              transform: rotate(0);
              transform-origin: 25% center;
              transition: transform 0.25s;
            }
          }

          &.user-menu-open .user-menu-arrow i {
            transform: rotate(90deg);
          }
        }

        .user-menu {
          position: absolute;
          z-index: 10;
          // border-top: 1px solid $blue1-dark;
          color: #b3b3b3;
          top: 65px;
          left: 0;
          width: 100%;
          // background: $blue1-light;
          background-color: white;
          transform: scaleY(0);
          transform-origin: top center;
          opacity: 0;
          box-shadow: 0.25em 0.25em 1em 0 rgba(0, 0, 0, 0.25);
          transition: transform 0.25s, opacity 0.25s;

          @media (max-width: $header-breakpoint-1) {
            font-size: 0.85em;
          }

          &.open {
            transform: scaleY(1);
            opacity: 1;
          }

          .user-info {
            display: none;
            padding: 0.5em 1em;
            text-align: right;
            background: rgba(0, 0, 0, 0.5);
            box-shadow: 0.15em 0.15em 0.5em 0 rgba(0, 0, 0, 0.25);
          }

          ul {
            @include unstyled-list;
            li {
              a {
                text-decoration: none;
                color: #666;
                display: block;
                padding: 1em;
                &:hover {
                  color: #fff;
                  background: #b3b3b3;
                }

                i {
                  width: 2em;
                }
                span:first-child {
                  margin-left: 2em;
                }
              }
            }
          }
        }
      }
    }

    .hamburger-nav {
      display: none;
      margin: 0 1em;

      .hamburger-menu-toggle {
        font-size: 2em;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        text-shadow: 0.15em 0.15em 0.5em rgba(0, 0, 0, 0.25);
      }
    }

    .hamburger-menu-toggle {
      font-size: 24px;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      text-shadow: 0.15em 0.15em 0.5em rgba(0, 0, 0, 0.25);
    }

    .sub-nav {
      background: $light-blue;

      ul {
        @include unstyled-list;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        min-height: 3.3em;
        height: 3.3em;
        align-items: center;

        @media (max-width: $header-breakpoint-1) {
          font-size: 0.85em;
        }

        li {
          display: block;
          .nav-link {
            display: inline-block;
            padding: 1em 2.5em;
            text-decoration: none;
            color: $color-text-normal;

            @media (max-width: $header-breakpoint-1) {
              padding: 1em 1.5em;
            }

            @media (max-width: $header-breakpoint-4) {
              padding: 1em 1em;
            }

            span.label {
              vertical-align: middle;
            }
          }
          &.active .nav-link span.label {
            font-weight: $weight-bold;
          }
          &.active,
          &:not(.disabled):hover .nav-link {
            background: $orange-light;
          }
          &.disabled {
            opacity: 0.25;
            cursor: default;
          }
        }
      }
    }
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  @media (max-width: $header-breakpoint-5) {
    .logo {
      min-width: 225px;
      flex-basis: 40%;
      a {
        .logo-split {
          display: none;
        }
        .logo-single {
          display: block;
        }
      }
    }

    .nav-panel {
      flex-basis: 60%;

      .top-nav-panel {
        height: 100%;
        justify-content: flex-end;
        align-items: center;
      }
      .top-nav-panel .main-nav {
        display: none;
      }

      .top-nav-panel .user-nav {
        min-width: 3em;
        max-width: 4em;
        flex-basis: 5%;

        .user-panel {
          &,
          &:hover {
            background: transparent;
          }

          .user-thumbnail {
            margin: 0.5em 0;
          }

          .user-menu-arrow {
            display: none;
          }

          .user-info {
            display: none;
          }
        }

        .user-menu {
          left: initial;
          right: 0;
          width: auto;
          min-width: 15em;

          .user-info {
            display: block;
          }
        }
      }

      .hamburger-nav {
        display: block;
      }

      .sub-nav {
        display: none;
      }
    }
  }
}

.nav-drawer {
  .nav-drawer-content {
    min-width: 15em;
    .main-nav {
      padding: 0.5em;
      ul {
        @include unstyled-list;
        .nav-item.main {
          display: inline-block;
          min-width: 5em;
          text-align: center;
          .nav-link i.icon {
            display: flex;
            margin: 0.5 auto;
          }
        }
      }
    }

    .sub-nav {
      margin-top: 1em;
      ul {
        @include unstyled-list;
        .nav-item.sub {
          .nav-link,
          &.active .nav-link {
            display: block;
            padding: 1em;

            cursor: pointer;
            color: #222;
            text-decoration: none;
          }

          &:not(.disabled) .nav-link:hover,
          &.active .nav-link {
            background: #eee;
          }

          &.disabled .nav-link {
            color: #ccc;
            cursor: default;
            &:hover {
              background: #fafafa;
            }
          }

          &.active .nav-link {
            font-weight: $weight-bold;
          }
        }
      }
    }
  }
}
