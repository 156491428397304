@import 'scss/variables.scss';

.incident-manager-form {
  margin: 20px 0;
  .incident-manager-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 25px;
    align-items: flex-start;

    @media (max-width: 840px) {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    .investigation-status {
      align-self: flex-start;
    }

    .date-inputs-row {
      .date-input {
        width: 100%;
        background-color: white;
      }
    }

    .affected {
      display: flex;
    }
  }

  .tabs {
    background-color: $grey200-1;
    height: 200px;
    margin: 100px 0;
    text-align: right;

    .tabs-container {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      grid-gap: 25px;
    }
  }

  .affected {
    display: flex;
  }

  .hr {
    margin: 50px auto;
  }

  .incident-description {
    width: 100%;
    background-color: white;
    border: 2px solid $grey300-2;
    padding: 25px;
    font-size: 1.8rem;
    color: $grey900-5;
    resize: none;
  }

  .form-actions {
    display: grid;
    grid-template-columns: max-content auto max-content max-content;
    grid-gap: 25px;

    .cancel-button {
      grid-column: -2/-3;
    }

    .update-incident-button,
    .resolved-incident-button {
      grid-column: -1/-2;
    }
  }
}

.notes_tab {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.notes_add {
  display: flex;
  padding: 0;

  textarea {
    padding: 8px;
    width: 100%;
    min-width: 300px;
    max-width: 1600px;
    min-height: 50px;
    max-height: 200px;
  }
}

.notes_list {
  margin: 0;
  padding: 0;
  margin-top: 60px;

  .add-note-table {
    margin: 0;
  }
}

.add-note-button {
  margin-top: 20px;
  margin-right: auto;
}

.add-note-heading {
  font-size: 2.4rem;
  margin: 0;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
