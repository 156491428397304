@import 'scss/variables.scss';

.login-panel-content {
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  grid-template-columns: minmax(450px, max-content);
  margin-bottom: 30px;

  .welcome {
    font-size: 2rem;
    margin: 0;
    margin-top: 40px;
  }

  .img {
    justify-self: center;
    height: 200px;
  }

  .heading {
    font-size: 2.8rem;
    color: $blue1-byRoland;
    font-weight: 500;
    margin-bottom: 0;
  }

  .password {
    padding-right: 43px;
  }

  .show-password {
    .password-button {
      position: absolute;
      right: 2px;
      top: 14px;
      width: 42px;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;

      i {
        font-size: 22px;
        color: #bbbbbb;
      }
    }
  }
}

.login-panel-heading {
  height: 100px;
  padding: 20px;
  background-color: $grey100;
  box-sizing: border-box;

  .logo {
    width: 350px;
  }
}

.login-panel-footer {
  padding: 20px 0;
  height: 120px;
  background-color: $grey100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid $grey200-1;
}

.copyright {
  position: absolute;
  bottom: 15px;
  color: white;
  opacity: 0.5;
  font-size: 1.4rem;
}
