@import 'scss/variables.scss';

.modal {
  padding: 0px;
  min-width: max-content;
  min-height: max-content;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .create {
    align-self: center;
    margin-left: 20px;
  }

  .modal-content {
    padding: 50px;
    display: flex;

    & > div {
      width: 100%;
      padding: 20px;
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }
}

.notes {
  resize: vertical;
  padding: 8px;
}

.button {
  margin: 0 10px;
}

.custom_fe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
}

.custom_fe_last {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
}

.custom_radio_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
}

.flex {
  display: flex;
  flex-direction: column;
}

.fe_notes {
  margin-top: 20px;
}

.fe_other {
  margin-top: 10px;
}
