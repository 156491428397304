@import 'scss/variables.scss';

.upload-label {
  height: 55px;
  border: 2px solid $grey300-2;
  // display: grid;
  // grid-template-columns: 300px 100px;
  // grid-gap: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  // max-width: 50%;

  .upload-placeholder {
    color: #333;
    text-indent: 10px;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .upload-button {
    background: $grey200-1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    margin-left: auto;
    user-select: none;
  }

  .upload-input {
    display: none;
  }
}

.error {
  font-size: 14px;
  color: #e2234b;
}
