@import 'scss/variables.scss';

.tab-incidents {
  margin-bottom: 50px;

  .header {
    display: flex;
    // grid-template-columns: max-content max-content auto max-content;
    align-items: center;
    margin: 20px auto;

    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 2.5rem;

      @media (max-width: 992px) {
        font-size: 2.1rem;
      }

      .header-slash {
        color: $grey500-3;
        font-weight: 300;
      }
    }

    i {
      color: $grey500-3;
    }

    .button {
      margin-left: auto;
      font-size: 1.4rem;

      @media (max-width: 992px) {
        font-size: 1.2rem;
        line-height: 38px;
        height: 40px;
        padding-left: 25px;
        padding-right: 20px;
      }

      .button-arrow {
        font-size: 1.4rem;
        color: white;
        margin-left: 20px;
      }
    }
  }

  .report-an-incident-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    grid-row-gap: 20px;
    align-items: flex-start;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }

    .incident-description {
      grid-row: span 2;
      grid-template-rows: min-content 1fr;
    }
    .incident-description-textarea {
      background-color: white;
      border: 2px solid $grey300-2;
      resize: none;
      padding: 1rem 1rem;
      font-size: 14px;
    }

    input[type='date']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }

    .date-inputs-row {
      .date-input {
        width: 100%;
        background-color: white;
      }
    }
  }
  .hr {
    margin: 100px auto 50px auto;
  }

  .actions {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: flex-end;
    grid-gap: 20px;
  }
}

.affected {
  display: flex;
}
