@import 'scss/variables.scss';

.access-permissions {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  .access-error {
    border-color: #e2234b !important;
  }

  .error-msg {
    margin: 15px 0;
    color: #e2234b;
    font-size: 1.4rem;
  }

  .dropdown {
    background-color: #fff;
    width: 100%;
    cursor: pointer;
    border: 0.12em solid $grey300-2;

    display: flex;
    flex-direction: column;
    align-items: center;

    .placeholder {
      width: 100%;
      display: grid;
      grid-template-columns: min-content auto min-content;
      align-items: center;
      min-height: 55px;
      padding: 20px;

      span {
        text-indent: 20px;
      }

      i {
        font-size: 2rem;

        &:first-child {
          color: $grey700-4;
        }

        &:last-child {
          color: $grey500-3;
        }
      }
    }

    .options {
      top: 55px;
      width: 100%;
      max-height: 500px;
      overflow-y: auto;
      z-index: 2;
      background: white;
      border: 0.12em solid $grey300-2;

      &.hidden {
        display: none;
      }

      .option {
        height: 55px;
        display: flex;
        align-items: center;
        padding-left: 50px;
        box-sizing: border-box;

        i {
          font-size: 14px;
        }

        &:hover {
          background-color: $blue2-light-hover;
        }

        @for $i from 1 through 3 {
          &.indent-#{$i} {
            padding-left: 50px * $i + 50px;
          }
        }
      }
    }
  }
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.select_item {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
  padding: 5px 0;
  height: 48px;
}

.select_item_org {
  padding-left: 0px;
}

.select_item_site {
  padding-left: 32px;
}

.select_item_dep {
  padding-left: 64px;
}

.drop {
  margin-left: auto;
  font-size: 12px;
  text-transform: uppercase;
  color: #444;

  select {
    padding: 6px;
    border: 2px solid #e3e3e3;
  }
}

.checkbox {
  margin-right: 5px;
}

select {
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px;
  min-width: 80px;
}

option {
  text-transform: uppercase;
  font-size: 12px;
  padding: 15px;
  line-height: 2;
}

.holder {
  border: 2px solid rgb(227, 227, 227);
  padding: 10px;
  min-height: 757px;
  max-height: 757px;
  overflow-y: auto;
}

.label {
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: inherit !important;
  cursor: pointer !important;
}

.search_holder {
  // margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .clear {
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    color: $blue1-byRoland;
  }

  .search_input {
    padding: 6px;
    border: 2px solid #e3e3e3;
    margin-right: auto;
    width: 200px;

    & > input {
      padding-left: 0px;
    }
  }
}

.access_filter {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  top: 0;
  font-size: 14px;
}
