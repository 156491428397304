@import 'scss/variables.scss';

.modal-site-details {
  padding: 0px;
  min-width: max-content;
  min-height: max-content;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  input {
    margin-bottom: 10px;
  }

  .create {
    align-self: center;
    margin-left: 20px;
  }
}

.input {
  position: relative;
  padding: 0;
  color: #212121;
  font-size: 1.4rem;
  font-weight: 400;
  box-sizing: border-box;
  height: 55px;
  display: flex;
  align-items: center;
  border-radius: 0%;
  border: 2px solid #e0e0e0;
  min-width: 0;
  text-indent: 1.2rem;
  background-color: white;
  width: 100%;
  appearance: none;
  text-transform: none;
  margin-bottom: 10px;
}

.input_holder {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '▼';
    display: flex;
    position: absolute;
    transform: scaleY(0.5);
    right: 10px;
    top: 20px;
    color: #9e9e9e;
  }
}
