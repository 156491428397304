@import 'scss/variables.scss';

.stat {
  background-color: white;
  padding: 10px 15px 5px 15px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  cursor: pointer;
  min-height: 72px;

  .stat-icon {
    font-size: 1.6rem;
    color: white;
    background-color: $blue2;
    padding: 20px;
    // border-radius: 2px;

    position: relative;
    top: -20px;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    @include boxshadow(2);
  }

  .stat-info {
    display: grid;
    justify-items: flex-end;

    .stat-name {
      color: $grey500-3;
      font-size: 1.6rem;
      font-weight: 300;
    }

    .stat-data {
      color: $grey900-5;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}
