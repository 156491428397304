@import 'scss/variables.scss';

.cost-input {
  display: flex;
  border: 2px solid $grey300-2;
  width: 200px;

  .cost-input-icon {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $grey100;
    padding: 10px 20px;
  }

  input {
    font-size: 1.4rem;
    border: 0;
    padding: 5px;
    background-color: white;
    height: 51px;
    // Removes input spinners but allows non-number characters
    // -webkit-appearance: textfield;
    // -moz-appearance: textfield;
    // appearance: textfield;
  }
}
