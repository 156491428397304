@import 'scss/variables.scss';

.wrapper {
  padding: 5px 30px 20px;
  max-width: 1140px;
  min-width: 720px;
  overflow: auto;
}

.loader {
  position: relative;
  div {
    position: absolute;
    z-index: 1;
  }
}

.main_title {
  margin: 35px 0 10px;
  font-size: 2rem;
  font-weight: 500;
}

.main_text {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $grey300-2;
  padding-top: 15px;
  padding-bottom: 20px;
}

.type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $grey300-2;
}
