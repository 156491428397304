@import 'scss/variables.scss';

.modal {
  .modal-content {
    padding: 30px 50px 50px;
    text-align: center;
  }

  .holder {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-footer {
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .deactivate {
    width: max-content;
    margin: 20px auto 0;
  }

  .button_group {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .resend {
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      color: $blue1-dark;
      text-decoration: underline;
    }
  }

  .confirm {
    width: 160px;
    margin: 0 auto;
  }

  .message {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
  }

  .master-desc {
    margin-bottom: 32px;
    line-height: 1.5;
  }

  .master-key {
    font-size: 24px;
  }

  .error-message-success {
    color: #34c759;
    font-size: 14px;
    margin-top: 12px;
  }

  .clipboard-button {
    border: none;
    background: transparent;
    margin-top: 24px;
    cursor: pointer;

    i {
      font-size: 18px;
    }
  }
}
