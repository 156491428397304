.statistics {
  width: 100%;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 124px;
  padding: 14px 0;
  background: white;
  color: #1c1c1e;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 3px;
  display: flex;

  .icon {
    width: 100px;
    position: relative;

    .icon__badge {
      position: absolute;
      top: -30px;
      left: 20px;
      background: #8e8e93;
      height: 48px;
      width: 48px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      text-align: center;
      color: white;
      font-size: 20px;

      & > i {
        position: relative;
        top: calc(50% - 10px);
      }
    }
  }
  .page {
    width: 40%;
    border-right: 1px solid #d1d1d6;

    @container (max-width: 480px) {
      width: 50%;
    }

    .page__title {
      font-weight: 500;
      font-style: normal;
      font-size: 23px;
      line-height: 34px;

      @container (max-width: 480px) {
        font-size: 16px;
      }
    }

    .page__stats {
      font-weight: 500;
      font-style: normal;
      font-size: 43px;
      line-height: 64px;
      display: inline-block;

      @container (max-width: 480px) {
        font-size: 32px;
      }
    }

    .page__stats-title {
      display: inline-block;

      small {
        display: block;
        font-weight: 400;
        font-size: 14px;
        margin-left: 10px;
        color: #636366;

        @container (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }
  .chart {
    width: 60%;
    height: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @container (max-width: 480px) {
      width: 50%;
    }

    .chart__graph {
      max-width: 60px;
      width: 100%;
      margin-right: 10px;

      @container (max-width: 480px) {
        width: 30px;
      }
    }

    .chart__stats {
      .chart__stats-title {
        color: #636366;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      .chart__stats-percent {
        font-weight: 500;
        font-size: 25px;
        line-height: 41px;
      }
    }
  }
}
