@import 'scss/variables.scss';

.register {
  background: #e3e3e3;
  display: flex;
  flex-direction: column;

  img {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 20px auto 40px auto;
  }

  h1,
  h3 {
    text-align: center;
    font-weight: 500;
  }
}

.holder {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 850px;
  margin-top: 20px;
  margin-bottom: 80px;

  .container {
    margin: 20px auto 60px auto;
    width: 100%;
    max-width: 460px;

    .text {
      text-align: center;
      margin-bottom: 40px;
      color: #636366;
    }
  }

  .form_element {
    margin-bottom: 25px;
  }

  .form_element_passowrd {
    // margin-bottom: 10px;
    position: relative;
  }

  .password {
    padding-right: 43px;
  }

  .show-password {
    .password-button {
      position: absolute;
      right: 2px;
      top: 42px;
      width: 42px;
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;

      i {
        font-size: 22px;
        color: #bbbbbb;
      }
    }
  }
}
