.accordion-item {
  .accordion-title-wrapper {
    display: flex;
    cursor: pointer;
    border-bottom: 2px solid #e0e0e0;
    padding: 10px 0;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
    align-items: center;

    .accordion-indicator {
      margin-right: 10px;
    }

    .accordion-extra-title {
      margin-left: auto;
      margin-right: 10px;
      font-size: 1.3rem;
    }
  }
}
