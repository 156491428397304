@import 'scss/variables.scss';

div.modal {
  height: initial;
  .modal-header,
  .modal-footer {
    background-color: $grey200-1;
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }

  .modal-body {
    // color: $grey500-3;
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    b,
    strong {
      color: $grey500-3;
      font-weight: 500;
    }

    small {
      color: $grey500-3;
      font-size: 12px;
      margin: auto 2px;
    }

    .existing-folder {
      display: grid;
      grid-template-columns: 1fr 2fr;
      width: 80%;
      grid-gap: 20px;

      label {
        text-align: left;
        height: 55px;
        color: $grey700-4;
        // text-transform: uppercase;
      }

      .admin-only,
      .permission-level-label {
        display: flex;
        align-items: center;
      }

      > input {
        height: 55px;
      }

      menu.parent-folder-select:hover {
        background-color: red;
      }

      .folder-name {
        padding: 0rem;
      }

      .parent-folder {
        list-style: none;
        text-align: left;
        height: 100%;
        padding: 0px;
        grid-gap: 5px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:hover {
          background-color: white;
        }

        .search-input {
          margin: 10px;
          width: 90%;
        }

        li.folder {
          display: grid;
          grid-template-columns: max-content 1fr;
          grid-column-gap: 10px;
          color: $grey700-4;
          align-items: center;
          padding-top: 8px;
          padding-bottom: 8px;
          width: 100%;

          i {
            font-size: 2.4rem;
          }

          &:hover {
            background-color: #e8f6fc;
          }
        }
        @for $i from 0 through 10 {
          .depth-#{$i} {
            padding-left: #{(($i) * 30 + 8)}px;
          }
        }
      }
    }

    .site-dept {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 15px;

      .permission-level {
        grid-row: 1/3;
      }
    }

    textarea {
      width: 100%;
      height: 55px;
      resize: none;
      padding: 1rem 1rem;
      font-size: 14px;
    }

    .button {
      width: max-content;
    }
  }

  .date-input {
    max-height: 55px;
    width: 100%;
    background-color: white;
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
}

.reg {
  display: flex;
  margin: auto 0;
  text-transform: uppercase;
}
