.user-menu {
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-family: roboto, sans-serif;
  line-height: 1.2;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  color: #666666;

  .user-name {
    text-transform: none;
  }

  .icon {
    margin-top: 3px;
    
    svg {
      height: 22px;
      width: 22px;
    }
  }

  .user-role {
    font-size: 12px;
    color: #777;
  }
}

.user-menu-dropdown {
  li {
    padding: 0;
  }
  a {
    text-decoration: none;
    color:#21a9e1;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    display: inline-block;
    padding: 7px 20px;

    i {
      margin-right: 10px;
    }
  }

  .user-menu-link {
    display: flex;
    align-items: center;

    svg {
      height: 18px;
      width: 18px;
    }

    .user-menu-label {
      margin-left: 10px;
    }
  }
}
