@import 'scss/variables.scss';

.panel {
  padding-bottom: 40px;

  .form-org-select,
  .form-site-select {
    max-width: 80%;
    margin: 20px auto;
  }

  .welcome {
    font-size: 2rem;
  }
}

.heading {
  height: 100px;
  padding: 20px;
  background-color: $grey100;
  box-sizing: border-box;

  .logo {
    width: 350px;
  }
}

.footer {
  padding: 20px 0;
  height: 120px;
  background-color: $grey100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid $grey200-1;
}
