@import 'scss/variables.scss';

.tabs {
  background-color: $grey200-1;
  // height: 85px;
  text-align: right;
  // margin: 20 auto;
  overflow: hidden;
  // padding-top: 15px;
  position: relative;
  user-select: none;

  .tabs-container {
    height: 85px;
    padding: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    // height: 100%;
    // align-items: center;
    // padding-top: 0px;
    // padding-bottom: 0px;

    & > div {
      margin-right: 20px;
    }
  }
}

@media (max-width: 1400px) {
  .dashboard-tabs {
    background-color: white;
    .tabs-container {
      transform: scale(0.8);
      transform-origin: bottom left;
    }
  }
}

@media (max-width: 1200px) {
  .dashboard-tabs {
    background-color: white;
    .tabs-container {
      transform: scale(0.7);
      transform-origin: bottom left;
    }
  }
}

@media (max-width: 992px) {
  .dashboard-tabs {
    .tabs-container {
      transform: scale(0.7);
      transform-origin: bottom left;
      margin-left: auto;

      & > div {
        min-width: 160px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard-tabs {
    .tabs-container {
      transform: scale(0.7);
      transform-origin: bottom left;
    }
  }
}
