@import 'scss/variables.scss';

.modal {
  .modal-heading {
    font-size: 2.4rem;
    font-weight: 500;
    text-align: center;
    grid-column: 1/-1;
  }

  .create {
    margin-left: 20px;
    align-self: center;
  }

  .modal-content {
    padding: 50px;

    & > div {
      margin-bottom: 25px;
    }
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 120px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 2.4rem;
    font-weight: 500;
    grid-column: 1/-1;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    background-color: $grey100;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

.dates_holder {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  & > div:last-of-type {
    margin-left: 20px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  margin-left: -5px;

  span {
    font-size: 15px;
    margin-left: 10px;
  }
}
