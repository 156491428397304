@import 'scss/variables.scss';

.clear-all-fields {
  color: $blue1-byRoland;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s;
  max-width: max-content;
  letter-spacing: 0.12rem;
  border: 2px solid $blue1-byRoland;
  border-radius: 40px;
  background-color: #fff;
  padding: 0 16px;

  &:hover {
    color: $blue1-dark;
    border-color: $blue1-dark;
    transition: all 0.1s;
  }

  .times-icon {
    font-size: 2.4rem;
  }

  .clear-all-fields-text {
    font-weight: 500;
    font-size: 1.6rem;
  }
}
