@import 'scss/variables.scss';

.message-box {
  background: $blue2-light-hover;
  color: $blue2;
  border: 2px solid $blue2;
  text-align: center;

  display: grid;
  grid-template-columns: 1fr 5rem;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 112px;

  &.hide {
    opacity: 0;
    transition: all 0.3s;
    height: 0;
    padding: 0;
  }

  span {
    grid-column: 1/2;
    grid-row: 1/2;
    text-align: left;
    font-size: 1.4rem;
  }

  i {
    font-size: 1.5rem;
    justify-self: flex-end;
    cursor: pointer;
  }
}
