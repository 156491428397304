@import 'scss/variables.scss';

div.modal {
  height: initial;
  .modal-header,
  .modal-footer {
    background-color: $grey200-1;
    height: 90px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      font-size: 2.6rem;
      font-weight: 400;
      margin: 0;
    }

    .description {
      color: $grey500-3;
      font-weight: 500;
    }
  }

  .modal-body {
    min-height: 300px;
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
}

//

.section_head {
  background-color: #666;
  color: #fff;
  font-weight: 400;
  padding: 15px;
  font-size: 16px;
  text-align: center;
}

.questions {
  font-size: 14px;

  & > div {
    padding: 8px 0;
    padding-left: 15px;
    display: flex;
    align-items: center;

    & > span {
      margin: 2px;
      min-width: 40px;
    }

    & > span:last-of-type {
      min-width: 240px;
      text-align: right;
      margin-left: auto;
      margin-right: 5px;

      input {
        cursor: pointer;
      }

      & > label {
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }

  div:nth-child(odd) {
    background: #ddd;
  }

  div:nth-child(even) {
    background: #fff;
  }
}
