@import 'scss/variables.scss';

.footer {
  @include footer;
  border-top: 1px solid $grey700-4;
  padding: 30px 60px;
  margin-top: auto;

  display: grid;
  grid-template-columns: max-content max-content auto max-content;
  grid-gap: 0 30px;

  .hipaa-seal-logo {
    width: 200px;
    // height: 150px;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .screen-tutorial-button {
    grid-column: 4/5;
    align-self: center;
    background-color: white;
    height: 55px;
    padding: 0 30px;
    border: 2px solid $blue1-byRoland;
    border-radius: 40px;
    color: $blue1-byRoland;
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;

    display: flex;
    align-items: center;
    margin-right: 60px;

    @media (max-width: 1024px) {
      margin-right: 0;
    }

    &:hover {
      background-color: $blue1-byRoland;
      color: white;
      transition: all 0.3s;
    }

    i {
      font-size: 2.4rem;
      margin-right: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    min-height: 400px;
  }
}
