@import 'scss/variables.scss';

.question-form {
  display: flex;
  flex-direction: column;
  margin: 0px 50px 30px 50px;
  min-height: 200px;

  .question {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 2.2rem;
    line-height: 1.4;
    font-weight: 500;
    color: $grey700-4;
  }

  .notes {
    resize: none;

    textarea {
      border: 2px solid $grey300-2;
      padding: 5px;
    }
  }
}

.answer_holder {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  margin-top: 30px;
  align-items: flex-start;
  & > div {
    margin-right: 40px;
  }
  & > div:last-of-type {
    margin-right: 0px;
  }
}
