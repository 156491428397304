.page-header-actions-training {
  @media (max-width: 1740px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.search {
  @media (max-width: 1740px) {
    width: 200px !important;
  }
}

.page-header-right {
  display: flex;
  align-items: center;
  // margin-bottom: 20px;
  @media (max-width: 1740px) {
    // display: none;
    margin-bottom: 20px;
    justify-content: right;
  }
}
